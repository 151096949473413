import React, { useState } from "react";
import "./style.scss";
//components
import Banner from "../../components/Banner/Banner";
import FaqItem from "../../components/FaqItem/FaqItem";
//images
import bannerimg from "../../images/faq/bannerimg.png";

const faqData = [
  {
    faqTitle:
      "What is the life of MCM products on the exterior facade and what will be the weathering condition on it?",
    faqDesc:
      "Our products have a certified life of 70 years (SGS). And regarding the weathering condition, there will be no colour change or fading of colour because it’s 100% natural.",
  },
  {
    faqTitle: "Are MCM products fire retardant?",
    faqDesc: "Yes, it is class A fire retardant.",
  },
  {
    faqTitle:
      "What are the chances of MCM products falling from a certain height like tiles/stones?",
    faqDesc:
      "Since the products are lightweight in nature, there’s a very rare chance of them falling from the facade.",
  },
  {
    faqTitle: "Are MCM products brittle?",
    faqDesc: "No, because it is made up of unfired cladding technology.",
  },
  {
    faqTitle:
      "Can we use MCM products in wet areas like Bathroom/Swiming pool/ waterfall areas?",
    faqDesc: "Yes, it can be used in wet areas.",
  },
  {
    faqTitle: "Can we do a 45° chamfer in MCM products?",
    faqDesc: "Yes, in a few products it is possible to do chamfering.",
  },
  {
    faqTitle:
      "As compared to any dry cladding or stone cladding the installation time involved is less or more?",
    faqDesc:
      "Yes, compared to any other cladding material, MCM products are fast and easy to install.",
  },
  {
    faqTitle: "Can we paint on the MCM products?",
    faqDesc: "Yes, exterior water-based paint can be applied to MCM products.",
  },
  {
    faqTitle:
      "On which all surfaces can we install MCM products and what surface preparation needs to be done before it?",
    faqDesc:
      "MCM can be pasted on any surface like Plaster, POP, Ply, Glass, Tiles, Marble, ACP, etc. and there is no requirement for any surface preparation only levelled surface will be sufficient.",
  },
  {
    faqTitle: "What is the approx thickness of all MCM products?",
    faqDesc: "Approx thickness of all MCM products is 2.5-3 mm.",
  },
  {
    faqTitle: "Are MCM products eco-friendly?",
    faqDesc:
      "MCM products are 100% eco-friendly and have global green building certificates.",
  },
  // {
  //   faqTitle:
  //     "What will be the effect on MCM products if there is any seepage or leakages on the interior wall?",
  //   faqDesc: "",
  // },
  // {
  //   faqTitle: "Does McM have Sound insulation property?",
  //   faqDesc: "",
  // },
  {
    faqTitle: "Is customisation possible in MCM products?",
    faqDesc: "Yes, customisation of natural colour is possible.",
  },
  {
    faqTitle:
      "What are the chances of green moss and fungal growth on MCM products during the rainy season?",
    faqDesc:
      "The chances of stagnant water on MCM product is very less thus the probability of green moss and fungal growth is low.",
  },
];

const Faq = () => {
  const [faqActiveIndex, setFaqActiveIndex] = useState(null);
  const handleFaqIndexClick = (i) => {
    setFaqActiveIndex(i);
  };
  const faqList = faqData.map((faq, i) => (
    <FaqItem
      faqTitle={faq.faqTitle}
      faqDesc={faq.faqDesc}
      key={i}
      faqIndex={i + 1}
      handleFaqIndexClick={handleFaqIndexClick}
      faqActiveIndex={faqActiveIndex}
    />
  ));

  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="FREQUENTLY ASKED QUESTIONS"
        breadcrumb2="FAQ's"
        disabledBreadcrumb2
      />
      <section className="faqsec2">
        <ul className="faq_list">{faqList}</ul>
      </section>
    </>
  );
};

export default Faq;
