import React, { useEffect, useState } from "react";
import "./style.scss";
//components
import Banner from "../../components/Banner/Banner";

//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

import bannerimg from "../../images/projects/bannerimg.jpg";
import { ctaRightArrow } from "../../images";
import { Link } from "react-router-dom";
import BlogCard from "../../components/BlogCard/BlogCard";
const Blogs = () => {
  const { width } = useWindowDimensions();
  const [blogsData, setBLogsData] = useState([
    {
      img: "https://dummyimage.com/707x486/fff",
      blogDate: "7th September, 2020",
      blogCat: "Category",
      blogTitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
      blogDesc:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum...",
    },
    {
      img: "https://dummyimage.com/707x486/fff",
      blogDate: "7th September, 2020",
      blogCat: "Category",
      blogTitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
      blogDesc:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum...",
    },
    {
      img: "https://dummyimage.com/707x486/fff",
      blogDate: "7th September, 2020",
      blogCat: "Category",
      blogTitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
      blogDesc:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum...",
    },
    {
      img: "https://dummyimage.com/707x486/fff",
      blogDate: "7th September, 2020",
      blogCat: "Category",
      blogTitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
      blogDesc:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum...",
    },
  ]);
  const blogsList = blogsData.map((blog, i) => (
    <div className="blog_card_wrapper" key={i}>
      <BlogCard
        blogImg={blog.img}
        blogTitle={blog.blogTitle}
        blogDesc={blog.blogDesc}
        blogDate={blog.blogDate}
        blogCategory={blog.blogCat}
      />
    </div>
  ));
  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="blogs"
        breadcrumb2="blogs"
        disabledBreadcrumb2
      />
      <section className="blogsec2">
        {width > 767 ? (
          <div className="featured_blog_flex">
            <div className="featured_blog_left">
              <div className="featured_date_and_cat">
                <span>7th September, 2020 | </span> <span>Category</span>
              </div>
              <h4 className="featured_blog_title">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </h4>
              <p className="featured_blog_desc">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidusus nt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus...
              </p>
              <Link to="/products" className="mcm_cta">
                <span>read more</span>
                <img
                  src={ctaRightArrow}
                  alt="MCM button right arrow"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="featured_blog_right">
              <img
                src="https://dummyimage.com/707x486/fff"
                alt="MCM featured blog"
                loading="lazy"
                width="707"
                height="486"
                className="featured_blog_img"
              />
            </div>
          </div>
        ) : null}
        <div className="blogs_flex">
          {width < 767 ? (
            <div className="blog_card_wrapper">
              <BlogCard
                blogImg="https://dummyimage.com/707x486/vbcbgc"
                blogTitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
                blogDesc="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum..."
                blogDate="7th September, 2020"
                blogCategory="Category"
              />
            </div>
          ) : null}
          {blogsList}
        </div>
        <div className="load_more_wrapper">
          <span className="load_more_cta">load more</span>
        </div>
      </section>
    </>
  );
};

export default Blogs;
