import _ from "lodash";
import stonethumb from "../images/products/stone_finish.jpg";
import bricksthumb from "../images/products/BRICK.jpg";
import woodthumb from "../images/products/WOOD.jpg";
export const datas = [
  {
    id: 1,
    title: "Stone",
    slug: "stones",
    desc: "MCM Stone Series has a fantastic collection of textures that look as natural as a stone. They are engineered to give any surface a minimal, sleek and exquisite fit and finish. Our stone effect products come in a great variety of natural shades, textures and sizes while also offering high durable performance, beauty and sustainability. Each of the materials offers an ageless finish with its own distinctive markings, designs, and texture. These amazing stone textures are long-lasting, strong by nature, and extraordinarily simple to install.",
    image: stonethumb,
  },
  {
    id: 2,
    title: "Brick",
    slug: "bricks",
    desc: "MCM Bricks Series give out a modern appearance to an otherwise dull area. We offer a vast abundance of brilliant natural brick imitations and emulated brick products. Offering a raw, and rustic effect to your spaces with exposed and burnt brick patterns, our series of brick is characterized by its even surface, small surface holes of varied shapes, slightly rounded mould-face touch effect and its own tile size dimensions which are similar to burnt bricks. High-grade raw materials brushed with advanced technology enable our professionals to design this cladding in accordance with contemporary trends in the industry. Our products meet every aspect of architectural needs and are typically chosen for their aesthetic qualities, classic beauty, strength, durability, and lightweight nature.",
    image: bricksthumb,
  },
  {
    id: 3,
    title: "Wood",
    slug: "wood",
    desc: "MCM Wood Series is a superb collection of natural wood textures, ideal for adding a rustic theme to any area! Their natural shades and colours provide an efficient alternative to natural wood and is made with highly sustainable materials. It is much more bendable, provides surface protection, are low-maintenance, non-combustible, and environment-friendly. Moreover, this wood is uninfluenced by the UV (does not change the colour), does not require nails, does not deform and is unaffected by insects, rain or direct sun heat. MCM Wood perfectly meets decorative needs while adding personality and vibrancy to any given structure. It helps to improve the microclimate in the living space by regulating the humidity within the optimal level for human beings.",
    image: woodthumb,
  },
];

export const findAll = () => {
  return datas;
};

export const findBySlug = (slug) => {
  return _.find(datas, { slug: slug });
};
