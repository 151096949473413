import React from "react";
import "./style.scss";
//components
import Banner from "../../components/Banner/Banner";
//images
import calender from "../../images/events/calender.svg";
import clock from "../../images/events/clock.svg";
import location from "../../images/events/location.svg";
import { ctaRightArrow, images } from "../../images";
import { eventsArr } from "../../Data/eventsData";

const Events = () => {
  const eventsList = eventsArr.map((item, i) => (
    <>
      {item.href !== "" && (
        <li className="event_item" key={i}>
          {/* <img
        src={item.img}
        alt="MCM event image"
        className="event_img"
        loading="lazy"
        width="354"
        height="237"
      /> */}
          <div className="event_details_wrapper">
            <h3 className="event_title">{item.title}</h3>
            <ul className="events_timing_details_flex">
              <li className="events_timing_item">
                <img
                  src={calender}
                  alt="event calender logo"
                  loading="lazy"
                  className="event_timing_logo"
                />
                <span>{item.date}</span>
              </li>
              {item.time !== "" && (
                <li className="events_timing_item">
                  <img
                    src={clock}
                    alt="event clock logo"
                    loading="lazy"
                    className="event_timing_logo"
                  />
                  <span>{item.time}</span>
                </li>
              )}
              <li className="events_timing_item">
                <img
                  src={location}
                  alt="event location logo"
                  loading="lazy"
                  className="event_timing_logo"
                />
                <span>{item.location}</span>
              </li>
            </ul>
            <p className="event_desc">{item.article}</p>
            {item.href !== "" && (
              <a
                href={item.href}
                target="_blank"
                className="mcm_cta"
                rel="noreferrer"
              >
                <span>Read more</span>
                <img
                  src={ctaRightArrow}
                  alt="MCM button right arrow"
                  loading="lazy"
                />
              </a>
            )}
          </div>
        </li>
      )}
    </>
  ));

  return (
    <>
      <Banner
        imgSrc={images.eventbanner.image}
        bannerTitle="events"
        breadcrumb2="events"
        disabledBreadcrumb2
      />
      <section className="eventssec2">
        <ul className="events_list">{eventsList}</ul>
        {/* <div className="load_more_wrapper">
          <span className="load_more_cta">load more</span>
        </div> */}
      </section>
    </>
  );
};

export default Events;
