import React from "react";
import Modal from "react-modal";
import "./style.scss";
import { modalcrosswhite } from "../../images";
Modal.setAppElement("#root");

const VideoModal = ({ isVideoModalOpen, ytCode, closeVideoModal }) => {
  return (
    <Modal
      isOpen={isVideoModalOpen}
      onRequestClose={closeVideoModal}
      contentLabel="MCM project detail gallery video"
      className="react_modal_content"
      overlayClassName="react_video_modal_overlay"
      preventScroll={true}
    >
      <img
        src={modalcrosswhite}
        alt="MCM modal close"
        loading="lazy"
        className="modal_close_icon"
        onClick={closeVideoModal}
      />

      <iframe
        src={`https://www.youtube.com/embed/${ytCode}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="project_detail_gallery_video"
      ></iframe>
    </Modal>
  );
};

export default VideoModal;
