import React, { useState } from "react";
import "./style.scss";

import { img404, ctaRightArrow, circle404 } from "../../images";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const PageNotFound = () => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);
  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: width > 992 && isLoader ? "block" : "none",
        }}
      >
        <Loader />
      </div>
      <section
        className="notfoundsec"
        style={{ visibility: width > 992 && isLoader ? "hidden" : "visible" }}
      >
        <div className="text_wrapper">
          <span className="oops_text">Oops!</span>
          <h1 className="text_404">
            4
            <img
              src={circle404}
              alt="MCM 404 circle"
              className="circle_404"
              loading="lazy"
            />
            4
          </h1>
          <p className="desc">
            We can't seem to find the page you're looking for. <br /> Error
            code: 404
          </p>
          <Link to="/" className="mcm_cta">
            <span>Go to homepage</span>
            <img
              src={ctaRightArrow}
              alt="MCM button right arrow"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="img_wrapper">
          <img
            src={img404}
            alt="404"
            className="img_404"
            onLoad={img404 ? imageLoaded : null}
            loading="lazy"
          />
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
