import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Link, NavLink } from "react-router-dom";
import FsLightbox from "fslightbox-react";

//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";

//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useDebounce from "../../hooks/useDebounce";

//images
import {
  ctaRightArrow,
  watchTriangle,
  navLeftArrow,
  navRightArrow,
  rightBlackArrow,
  leftBlackArrow,
  images,
  scroll,
} from "../../images";
import Home_Page_Banner from "../../images/home/Home_Page_Banner_1.jpg";
import Home_Page_mobBanner from "../../images/home/mcm-mobile-banner.jpg";
import { products } from "../../Data/ProductsData";
import ProductSwiper from "../../components/ProductSwiper/ProductSwiper";
import Loader from "../../components/Loader/Loader";
import { Helmet } from "react-helmet";
import { findAll as getAllCategories } from "../../Data/productCategories";
import {
  whymcmArr,
  productImgArr,
  productHollowNameArr,
  applicationsNamesArr,
  applicationImgArr,
  successLogoArr,
} from "../../Data/homepageData";

Swiper.use([Navigation, Autoplay]);

const Homepage = () => {
  const { width } = useWindowDimensions();
  const [applicationIndex, setApplicationIndexIndex] = useState(0);
  const debouncedIndex = useDebounce(applicationIndex, 0);
  const [isLoader, setIsLoader] = useState(true);
  const [watchPop] = useState(false);
  const aboutusref = useRef();
  const productref = useRef();
  const homesec3ref = useRef();
  const homesec4ref = useRef();
  const homesec5ref = useRef();
  const applicationSliderRef = useRef(0);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    var mb_product_swiper = new Swiper(".swiper-container.mb_product_swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".mb_product_swiper_cont .nav_arrow_right",
        prevEl: ".mb_product_swiper_cont .nav_arrow_left",
      },
      speed: 1000,

      loop: true,
      autoplay: {
        disableOnInteraction: false,
      },
    });

    var mb_app_swiper_container = new Swiper(
      ".swiper-container.mb_app_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".mb_app_swiper_wrapper .nav_arrow_right",
          prevEl: ".mb_app_swiper_wrapper .nav_arrow_left",
        },
      }
    );

    var application_desktop_swiper = new Swiper(
      ".swiper-container.application_swiper",
      {
        slidesPerView: 1,
        speed: 1,
        spaceBetween: 20,
        allowTouchMove: false,
      }
    );

    var install_swiper_container = new Swiper(
      ".swiper-container.install_swiper_container",
      {
        // spaceBetween: 20,
        allowTouchMove: false,
        navigation: {
          nextEl: ".install_container .nav_arrow_right",
          prevEl: ".install_container .nav_arrow_left",
        },
        // speed: 1000,

        loop: true,
        autoplay: {
          disableOnInteraction: false,
        },
        breakpoints: {
          0: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          600: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          1023: {
            slidesPerView: 2,
          },

          1194: {
            slidesPerView: 2.85,
          },
        },
      }
    );

    var testimonial_swiper_container = new Swiper(
      ".swiper-container.testimonial_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        allowTouchMove: false,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
        },
        speed: 1000,
        navigation: {
          nextEl: ".testimonial_swiper_cont .next_text",
          prevEl: ".testimonial_swiper_cont .prev_text",
        },
      }
    );

    var success_swiper_container = new Swiper(
      ".swiper-container.success_swiper_container",
      {
        loop: true,
        loopedSlides: successLogoArr.length,
        freeMode: true,
        allowTouchMove: false,
        autoplay: {
          delay: 1,
        },
        speed: 6000,
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          767: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1023: {
            slidesPerView: 5,
          },
        },
      }
    );

    var whymcm_swiper_container = new Swiper(
      ".swiper-container.whymcm_swiper_container",
      {
        loop: true,
        loopedSlides: successLogoArr.length,
        freeMode: true,
        allowTouchMove: false,
        autoplay: {
          delay: 1,
        },
        speed: 7000,
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 3.5,
            spaceBetween: 0,
          },
          1023: {
            slidesPerView: 4,
          },
        },
      }
    );
  }, []);

  const scrollDown = () => {
    const id = "homesec2";
    const yOffset = -60;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleAppSlideIndex = (i) => {
    setApplicationIndexIndex(i);
    applicationSliderRef.current?.swiper.slideTo(i);
  };

  useEffect(() => {
    applicationSliderRef.current?.swiper.on("slideChange", function (e) {
      setApplicationIndexIndex(
        applicationSliderRef.current?.swiper.activeIndex
      );
    });
  }, [applicationSliderRef]);

  const mbProductImgList = productImgArr.map((productImg, i) => (
    <div className="swiper-slide" key={i}>
      <img
        src={productImg}
        alt="MCM product"
        className="mb_productimg"
        loading="lazy"
      />
      <span className="hollow_text">{productHollowNameArr[i]}</span>
    </div>
  ));

  const whyMCMList = whymcmArr.map(({ img, name }, i) => (
    <div className="swiper-slide" key={i}>
      <div className="flex_circle">
        <div className="ring_wrapper">
          <img src={img} alt="MCM logo" loading="lazy" className="why_logo" />
        </div>
        <h4>{name}</h4>
      </div>
    </div>
  ));

  const handleApplicationMouseOver = (i) => {
    setApplicationIndexIndex(i);
  };
  const applicationsNamesList = applicationsNamesArr.map((appName, i) => (
    <li
      className={`application ${applicationIndex === i ? "active" : ""}`}
      key={i}
      onClick={() => handleAppSlideIndex(i)}
    >
      {appName}
    </li>
  ));

  const mbApplicationsList = applicationImgArr.map((appData, i) => (
    <li className="swiper-slide" key={i}>
      <div className="mb_app_slide_wrapper">
        <div className="mb_hollow_text">{appData.hollowText}</div>
        <img
          src={appData.thumbImg}
          alt="MCM application"
          loading="lazy"
          width="211"
          height="211"
          className="mb_app_thumb_image"
        />
        <img
          src={appData.thumbMain}
          alt="MCM application"
          loading="lazy"
          width="953"
          height="853"
          className="mb_app_main_image"
        />
      </div>
    </li>
  ));
  const applicationsList = applicationImgArr.map((appData, i) => (
    <>
      <div className="swiper-slide" key={i}>
        <div className="application_box">
          <span className="hollow_text">{appData.hollowText}</span>
          <img
            src={appData.thumbImg}
            alt="MCM applications"
            loading="lazy"
            className="app_thumb_image"
            width="211"
            height="211"
          />
          <img
            src={appData.thumbMain}
            alt="MCM applications"
            loading="lazy"
            width="953"
            height="853"
            className="app_main_image"
          />
        </div>
      </div>
    </>
  ));

  // const successLogosList = successLogoArr.map((succImg, i) => (
  //   <li className="swiper-slide" key={i}>
  //     <img
  //       src={succImg}
  //       alt="MCM success story"
  //       className="success_logo_img"
  //       width="227"
  //       height="95"
  //       loading="lazy"
  //     />
  //   </li>
  // ));

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={Home_Page_mobBanner && Home_Page_Banner}
        />
      </Helmet>
      <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div>
      <section className="homesec1">
        <div className="container">
          <div className="reveal">
            <img
              src={width < 600 ? Home_Page_mobBanner : Home_Page_Banner}
              alt="MCM banner"
              className="banner_img"
              onLoad={
                Home_Page_mobBanner && Home_Page_Banner ? imageLoaded : null
              }
            />
          </div>
        </div>
        <div className="text_conatiner">
          <div className="products_name_flex">
            {getAllCategories().map((item, i) => (
              <>
                <span className="brick_span">
                  <NavLink
                    to={{
                      pathname: "/products",
                      state: { slug: item.slug },
                    }}
                    className="inside_link"
                  >
                    {item.title}
                  </NavLink>
                </span>
              </>
            ))}
          </div>
          <h1>
            <span>Revolutionary</span> <span>Cladding</span>{" "}
            <span>Systems</span>
          </h1>
          <div className="watch_video_flex">
            <span className="watch_text">Watch the video</span>
            <span
              className="watch_icon_wrapper"
              // onClick={() => setWatchPop(!watchPop)}
            >
              <img
                src={watchTriangle}
                alt="MCM pause icon"
                className="watch_triangle"
                loading="lazy"
              />
            </span>
          </div>
        </div>
        <span className="scroll_down" onClick={scrollDown}>
          <img
            className="scroll_gif"
            src={scroll}
            loading="lazy"
            alt="Scroll_arrow"
          />
        </span>
      </section>

      <section className="homesec2" id="homesec2">
        <div className="aboutsec" ref={aboutusref}>
          <div className="about_flex">
            <div className="about_flex_left">
              <div className="heading_container">
                <h3 id="aboutus">about us</h3>
              </div>
              <div className="left_para_container">
                <p className="left_para" id="left_para">
                  PATENTED FAÇADE SYSTEM
                </p>
              </div>
            </div>
            <div className="about_flex_right">
              <p className="para_right">
                Exquisite exterior finishes and exclusive interiors are
                fabricated at MCMFLEXI Cladding India Pvt. Ltd. With its base in
                Mumbai, it is the most renowned, original and covetable pioneer
                player in the unfired clay cladding market in India. At MCM, we
                have set cross-national benchmarks with a concoction of
                technology, research and innovation.
                <br />
                <br />
                MCMFLEXI offers the most varied collection of natural, durable,
                stylish, and aesthetic products under MCM Stone, MCM Bricks and
                MCM Wood categories for multipurpose exterior and interior
                requirements.
                <br />
                <br />
                MCM has a new-age commitment to promoting sustainable and
                revolutionary cladding to build smart and of its kind living
                spaces. The patented cladding automation is transforming the
                construction industry by providing fast-paced & lightweight
                cladding solutions for distinct types of structures.
                <br />
                <br />
                The clay is constituted from recycled materials to reduce the
                carbon footprint. Being the purest form of nature, our modified
                clay material has got a green rating across the globe.
              </p>
            </div>
          </div>
        </div>
        <div className="productsec" ref={productref}>
          {width > 767 ? (
            <ProductSwiper />
          ) : (
            <div className="mb_product_content">
              <div className="text_container">
                <h3>products</h3>
                <p>
                  MCM is an amalgamation of clay and sand through patented
                  unfired manufacturing technology. Our wide range of products
                  offer natural finishes with a perfect combination of style,
                  aesthetics and performance.
                </p>
              </div>
              <div className="mb_product_swiper_cont">
                <div className="swiper-container mb_product_swiper">
                  <div className="swiper-wrapper">{mbProductImgList}</div>
                </div>
                <img
                  src={navLeftArrow}
                  alt="MCM navigation logo"
                  className="nav_arrow nav_arrow_left"
                  loading="lazy"
                />
                <img
                  src={navRightArrow}
                  alt="MCM navigation logo"
                  className="nav_arrow nav_arrow_right"
                  loading="lazy"
                />
              </div>
              <Link to="/products" className="mcm_cta">
                <span>know more</span>
                <img
                  src={ctaRightArrow}
                  loading="lazy"
                  alt="MCM button right arrow"
                />
              </Link>
            </div>
          )}
        </div>

        {/* <div className="container">
          <div className="product">
            <div className="product_hollow_text">
              <span className="hollow_text">wood Finish</span>
            </div>
            <img
              src={wood}
              alt="wood"
              className="product_img_1"
              width="1672"
              height="864"
                loading="lazy"
            />
            <div className="text_container_1">
              <h3>products</h3>
              <p>
                MCM is an amalgamation of clay and sand through patented unfired
                manufacturing technology. Our wide range of products offers
                natural finishes with a perfect combination of style, aesthetics
                and performance.
              </p>
            </div>
            <div className="product_switch">
              <span className="product_name ">MCM Bricks Series</span>
              <span className="product_name middle_product_name_1">
                MCM Stone Series
              </span>
              <span className="product_name">MCM Wood Series</span>
            </div>
            <div className="product_btn mcm_cta">
              <span>know more</span>
              <img
                src={ctaRightArrow}
                alt="MCM button right arrow"
                loading="lazy"
              />
            </div>
          </div>
        </div> */}
      </section>

      <section className="homesec3" ref={homesec3ref}>
        <div className="text_container">
          <h3 id="whymcm">why MCM?</h3>
          <div className="desc">
            MCM’s revolutionary products are ecological cladding materials that
            are the closest and purest form of nature, catering to today’s
            fast-track construction needs with sturdy performance. These
            products offer a unique blend of benefits like:
          </div>
        </div>
        <div className="why_flex">
          <div className="swiper-container whymcm_swiper_container">
            <div className="swiper-wrapper">{whyMCMList}</div>
          </div>
        </div>
      </section>

      <section className="homesec4" ref={homesec4ref}>
        <div className="applications_flex">
          <div
            className={`applications_left ${
              applicationIndex === applicationImgArr[debouncedIndex]
                ? "active"
                : ""
            }`}
          >
            {width >= 1024 ? (
              <>
                <div
                  className="swiper-container application_swiper"
                  ref={applicationSliderRef}
                >
                  <div className="swiper-wrapper">{applicationsList}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb_app_swiper_wrapper">
                  <div
                    className="swiper-container mb_app_swiper_container"
                    ref={applicationSliderRef}
                  >
                    <ul className="swiper-wrapper">{mbApplicationsList}</ul>
                  </div>
                  <img
                    src={navLeftArrow}
                    alt="MCM navigation logo"
                    className="nav_arrow nav_arrow_left"
                    loading="lazy"
                  />
                  <img
                    src={navRightArrow}
                    alt="MCM navigation logo"
                    className="nav_arrow nav_arrow_right"
                    loading="lazy"
                  />
                  <Link to="/projects" className="mcm_cta">
                    <span>know more</span>
                    <img
                      src={ctaRightArrow}
                      loading="lazy"
                      alt="MCM button right arrow"
                    />
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="applications_right">
            <div className="text_container">
              <h3>applications</h3>
              <p className="desc">
                MCM products are extremely versatile and offer a variety of
                application solutions for exterior and interior cladding
                requirements that are simple, easy and fast to install!
              </p>
            </div>
            {width >= 1024 ? (
              <>
                <ul>{applicationsNamesList}</ul>
                <Link to="/projects" className="mcm_cta">
                  <span>know more</span>
                  <img
                    src={ctaRightArrow}
                    alt="MCM button right arrow"
                    loading="lazy"
                  />
                </Link>
              </>
            ) : null}
          </div>
        </div>
      </section>

      <section className="homesec5" ref={homesec5ref}>
        <div className="text_container">
          <h3>installation</h3>
          <p className="desc">
            Our distinctive products are safe to fix and require close to no
            maintenance and installation hassles. It is as easy as cutting,
            applying the adhesive and pasting to give a new look to provide a
            stunning appearance to practically any substrate. The 5 simple steps
            are:
          </p>
        </div>
        <div className="install_container">
          <div className="swiper-container install_swiper_container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <div className="install_img_wrapper">
                  <img
                    src={images.surfaceprep.image}
                    alt={images.surfaceprep.alt}
                    loading="lazy"
                    className=""
                    width="486"
                    height="677"
                  />
                </div>
                <span className="install_count">01</span>
                <span className="install_title">Surface Preparation</span>
              </li>
              <li className="swiper-slide">
                <div className="install_img_wrapper">
                  <img
                    src={images.cutting.image}
                    alt={images.cutting.alt}
                    loading="lazy"
                    className=""
                    width="486"
                    height="677"
                  />
                </div>
                <span className="install_count">02</span>
                <span className="install_title">Cutting</span>
              </li>
              <li className="swiper-slide">
                <div className="install_img_wrapper">
                  <img
                    src={images.pasting.image}
                    alt={images.pasting.alt}
                    loading="lazy"
                    className=""
                    width="486"
                    height="677"
                  />
                </div>
                <span className="install_count">03</span>
                <span className="install_title">Pasting</span>
              </li>
              <li className="swiper-slide">
                <div className="install_img_wrapper">
                  <img
                    src={images.grouting.image}
                    alt={images.grouting.alt}
                    loading="lazy"
                    className=""
                    width="486"
                    height="677"
                  />
                </div>
                <span className="install_count">04</span>
                <span className="install_title">Grouting</span>
              </li>
              <li className="swiper-slide">
                <div className="install_img_wrapper">
                  <img
                    src={images.cleaning.image}
                    alt={images.cleaning.alt}
                    loading="lazy"
                    className=""
                    width="486"
                    height="677"
                  />
                </div>
                <span className="install_count">05</span>
                <span className="install_title">Cleaning</span>
              </li>
            </ul>
          </div>
          <img
            src={leftBlackArrow}
            alt="MCM navigation logo"
            className="nav_arrow nav_arrow_left"
            loading="lazy"
          />
          <img
            src={rightBlackArrow}
            alt="MCM navigation logo"
            className="nav_arrow nav_arrow_right"
            loading="lazy"
          />
        </div>
      </section>

      {/*  <section className="homesec6">
        <div className="text_container">
          <h3>our success partners</h3>
          <p className="desc">
            With MCM's journey over the years, we have been through a saga of
            pioneering initiatives and have constantly expanded the range of
            products and finishes. Our success partners belong to cross-sectoral
            industries including Commercial, Residential, Institutional,
            Hospitality, Healthcare and so on.
          </p>
        </div>
        <div className="success_swiper_cont">
          <img
            src={leftblur}
            alt="MCM success blur"
            className="blur_img blur_left"
            loading="lazy"
          />
          <img
            src={rightblur}
            alt="MCM success blur"
            className="blur_img blur_right"
            loading="lazy"
          />
          <div className="swiper-container success_swiper_container">
            <ul className="swiper-wrapper">{successLogosList}</ul>
          </div>
        </div>
      </section>

      <section className="homesec7">
        <div className="testimonial_flex">
          <div className="testimonial_left">
            <div className="text_container">
              <img
                src={quote}
                alt="MCM testimonial quote"
                loading="lazy"
                className="quote_logo"
              />
              <h3>testimonials</h3>
            </div>
            <div className="testimonial_swiper_cont">
              <div className="swiper-container testimonial_swiper_container">
                <ul className="swiper-wrapper">
                  <li className="swiper-slide">
                    <div className="testimonial_text_wrapper">
                      <h4 className="testimonial_title">Jane Doe</h4>
                      <p className="desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, Lorem ipsum dolor sit amet,
                        consetetur dolor ser sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat
                      </p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="testimonial_text_wrapper">
                      <h4 className="testimonial_title">Jane Doe</h4>
                      <p className="desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, Lorem ipsum dolor sit amet,
                        consetetur dolor ser sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="prev_next_wrapper">
                <span className="prev_text">prev</span>
                <span className="next_text">next</span>
              </div>
            </div>
          </div>
          <div className="testimonial_right">
            <div className="testi_img_wrapper">
              <img
                src={testiimg}
                alt="MCM testimonial"
                className="testiimg"
                loading="lazy"
                width="812"
                height="609"
              />
              <img
                src={testiplayicon}
                alt="MCM testimonial play icon"
                className="testiplayicon"
                loading="lazy"
                width="153"
                height="153"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* <div className="dummy"></div> */}
      <FsLightbox
        toggler={watchPop}
        sources={[
          "https://i.imgur.com/fsyrScY.jpg",
          "https://www.youtube.com/watch?v=z4uK4Pby3G0",
          "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        ]}
      />
    </>
  );
};

export default Homepage;
