import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";
//components
import Banner from "../../components/Banner/Banner";
import VideoModal from "../../components/VideoModal/VideoModal";

//images
import { navLeftArrow, navRightArrow, testiplayicon } from "../../images";
import { findBySlug as getSingleProject } from "../../Data/projects";
import { findBySlug as getSingleCategory } from "../../Data/projectCategories";

Swiper.use([Navigation, Autoplay]);

const ProjectsDetail = (props) => {
  const [product, setProduct] = useState({});
  const [category, setCategory] = useState({});
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [ytCode, setYtCode] = useState(null);
  const detailRef = useRef();

  useEffect(() => {
    var project_detail_swiper_container = new Swiper(
      ".swiper-container.project_detail_swiper_container",
      {
        navigation: {
          nextEl: ".nav_arrow_wrapper .nav_arrow_right",
          prevEl: ".nav_arrow_wrapper .nav_arrow_left",
        },
        loop: false,
        autoplay: {
          disableOnInteraction: false,
        },
        speed: 500,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          1023: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1194: {
            slidesPerView: 1.3,
          },
          1365: {
            slidesPerView: 1.4,
          },
          1661: {
            slidesPerView: 1.2,
          },
          2550: {
            slidesPerView: 1.4,
          },
        },
      }
    );
  }, [product, category]);

  const openVideoModal = (ytCode) => {
    setYtCode(ytCode);
    setIsVideoModalOpen(true);
  };
  const closeVideoModal = () => setIsVideoModalOpen(false);

  const projectGalleryList = product?.galleries?.map((item, i) => (
    <li className="swiper-slide" key={i}>
      <figure className="project_detail_img_wrapper">
        <img
          src={item.thumbnail.image}
          alt="MCM project detail"
          className="project_detail_img"
          loading="lazy"
          width="1226"
          height="589"
        />
        {item.type === "video" ? (
          <img
            src={testiplayicon}
            alt="MCM project details play icon"
            loading="lazy"
            className="testiplayicon"
            onClick={() => openVideoModal(item.embed_code)}
          />
        ) : null}
      </figure>
    </li>
  ));

  useEffect(() => {
    const productData = getSingleProject(props.match.params.slug);

    if (!productData) {
      props.history.push("/404");
    } else {
      const categoryData = getSingleCategory(productData.category);

      console.log({ productData });
      setProduct(productData);
      console.log({ categoryData });
      setCategory(categoryData);
      // const projectsData = getProjects(props.match.params.slug);
      // const categoriesData = getAllCategories();
      // console.log({ projectsData });
      // console.log({ categoriesData });

      // setProjects(projectsData);
      // setCategories(categoriesData);
    }
  }, [props.match.params.slug]);

  return (
    <>
      <Banner
        imgSrc={product?.banner?.image}
        bannerTitle={product.name}
        breadcrumb2="projects"
        breadcrumbLink2="/projects"
        breadcrumb3={category.title}
        breadcrumbLink3={`/projects/${category.slug}`}
        breadcrumb4={product.name}
        dropShadow={true}
      />
      <section className="projectdetailsec2" ref={detailRef}>
        <div className="text_container_flex">
          {/* <div className="flex_left">
            <div className="heading_container">
              <h3 className="heading_anim">project details</h3>
            </div>
            <p className="left_para">{product.title}</p>
          </div>
          <div className="flex_right">
            <p className="para_right">{product.description}</p>
          </div> */}
        </div>
        {projectGalleryList?.length > 0 && (
          <div className="project_detail_slider">
            <span className="hollow_text">gallery</span>
            <div className="swiper-container project_detail_swiper_container">
              <ul className="swiper-wrapper">{projectGalleryList}</ul>
            </div>
            <div className="nav_arrow_wrapper">
              <img
                src={navLeftArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_left"
              />
              <img
                src={navRightArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_right"
              />
            </div>
          </div>
        )}
      </section>
      <VideoModal
        isVideoModalOpen={isVideoModalOpen}
        ytCode={ytCode}
        closeVideoModal={closeVideoModal}
      />
    </>
  );
};

export default ProjectsDetail;
