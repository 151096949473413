import {
  Durable,
  Ecological,
  ExteriorGrade,
  Firereistant,
  Flexible,
  images,
  Shatterproof,
  Slim,
} from "../images";

import productimg from "../images/home/productimg.jpg";
import productstone from "../images/home/productstone.jpg";
import productwood from "../images/home/woodfinish_1.jpg";
import appthumb from "../images/home/app-thumb.png";
import appmain from "../images/home/app-main.png";

export const whymcmArr = [
  { img: images.thin.image, name: "Thin" },
  { img: images.ceramic.image, name: "Ceramic" },
  { img: ExteriorGrade, name: "Exterior Surface Grade" },
  { img: images.weatherproof.image, name: "Weatherproof" },
  { img: Flexible, name: "Flexible" },
  { img: Shatterproof, name: "Shatterproof" },
  { img: Durable, name: "Durable" },
  { img: Firereistant, name: "Fire Resistant" },
  { img: images.waterresistant.image, name: "Water-Resistant" },
  { img: Ecological, name: "Ecological" },
  {
    img: images.easytoinstall.image,
    name: "Easy To Install",
  },
];

export const productImgArr = [productimg, productstone, productwood];
export const productHollowNameArr = [
  "brick finish",
  "stone finish",
  "wood finish",
];
export const applicationsNamesArr = [
  "external cladding",
  "internal cladding",
  "ceiling",
  "landscaping",
  "furniture covering",
  "flooring",
];

export const applicationImgArr = [
  {
    thumbImg: appthumb,
    thumbMain: appmain,
    hollowText: "external cladding",
  },
  {
    thumbImg: images.stonehomeicon.image,
    thumbMain: images.stonehome.image,
    hollowText: "internal cladding",
  },
  {
    thumbImg: images.ceilingicon.image,
    thumbMain: images.ceilinghomeapplication.image,
    hollowText: "ceiling",
  },

  {
    thumbImg: images.landscapinghomeicon1.image,
    thumbMain: images.landscapinghome1.image,
    hollowText: "landscaping",
  },
  {
    thumbImg: images.furniturecapinghomeicon1.image,
    thumbMain: images.furniturecapinghome1.image,
    hollowText: "furniture covering",
  },

  {
    thumbImg: images.flooringhomeicon.image,
    thumbMain: images.flooringhome1.image,
    hollowText: "flooring",
  },
];
export const successLogoArr = [];
