import _ from "lodash";
import { images, productsImg } from "../images";
export const datas = [
  {
    id: 1,
    category: "bricks",
    name: images.brick1.alt,
    thumbnail: images.brick1,
  },
  {
    id: 2,
    category: "bricks",
    name: images.brick2.alt,
    thumbnail: images.brick2,
  },
  {
    id: 3,
    category: "bricks",
    name: images.brick3.alt,
    thumbnail: images.brick3,
  },
  {
    id: 4,
    category: "bricks",
    name: images.brick4.alt,
    thumbnail: images.brick4,
  },
  {
    id: 5,
    category: "bricks",
    name: images.brick5.alt,
    thumbnail: images.brick5,
  },
  {
    id: 6,
    category: "bricks",
    name: images.brick6.alt,
    thumbnail: images.brick6,
  },
  {
    id: 7,
    category: "bricks",
    name: images.brick7.alt,
    thumbnail: images.brick7,
  },
  {
    id: 8,
    category: "bricks",
    name: images.brick8.alt,
    thumbnail: images.brick8,
  },
  {
    id: 9,
    category: "bricks",
    name: images.brick9.alt,
    thumbnail: images.brick9,
  },
  {
    id: 10,
    category: "bricks",
    name: images.brick10.alt,
    thumbnail: images.brick10,
  },
  {
    id: 11,
    category: "bricks",
    name: images.brick11.alt,
    thumbnail: images.brick11,
  },
  {
    id: 12,
    category: "bricks",
    name: images.brick12.alt,
    thumbnail: images.brick12,
  },
  {
    id: 13,
    category: "bricks",
    name: images.brick13.alt,
    thumbnail: images.brick13,
  },

  {
    category: "stones",
    name: images.stone1.alt,
    thumbnail: images.stone1,
  },
  {
    category: "stones",
    name: images.stone2.alt,
    thumbnail: images.stone2,
  },
  {
    category: "stones",
    name: images.stone3.alt,
    thumbnail: images.stone3,
  },
  {
    category: "stones",
    name: images.stone4.alt,
    thumbnail: images.stone4,
  },
  {
    category: "stones",
    name: images.stone5.alt,
    thumbnail: images.stone5,
  },
  {
    category: "stones",
    name: images.stone6.alt,
    thumbnail: images.stone6,
  },
  {
    category: "stones",
    name: images.stone7.alt,
    thumbnail: images.stone7,
  },
  {
    category: "stones",
    name: images.stone8.alt,
    thumbnail: images.stone8,
  },
  {
    category: "stones",
    name: images.stone9.alt,
    thumbnail: images.stone9,
  },
  {
    category: "stones",
    name: images.stone10.alt,
    thumbnail: images.stone10,
  },
  {
    category: "stones",
    name: images.stone11.alt,
    thumbnail: images.stone11,
  },
  {
    category: "stones",
    name: images.stone12.alt,
    thumbnail: images.stone12,
  },
  {
    category: "stones",
    name: images.stone13.alt,
    thumbnail: images.stone13,
  },
  {
    category: "stones",
    name: images.stone14.alt,
    thumbnail: images.stone14,
  },
  {
    category: "stones",
    name: images.stone15.alt,
    thumbnail: images.stone15,
  },
  {
    category: "stones",
    name: images.stone16.alt,
    thumbnail: images.stone16,
  },
  {
    category: "stones",
    name: images.stone17.alt,
    thumbnail: images.stone17,
  },
  {
    category: "stones",
    name: images.stone18.alt,
    thumbnail: images.stone18,
  },
  {
    category: "stones",
    name: images.stone19.alt,
    thumbnail: images.stone19,
  },
  {
    category: "stones",
    name: images.stone20.alt,
    thumbnail: images.stone20,
  },
  {
    category: "stones",
    name: images.stone21.alt,
    thumbnail: images.stone21,
  },
  {
    category: "stones",
    name: images.stone22.alt,
    thumbnail: images.stone22,
  },
  {
    category: "stones",
    name: images.stone23.alt,
    thumbnail: images.stone23,
  },
  {
    category: "stones",
    name: images.stone24.alt,
    thumbnail: images.stone24,
  },
  {
    category: "stones",
    name: images.stone25.alt,
    thumbnail: images.stone25,
  },
  {
    category: "stones",
    name: images.stone26.alt,
    thumbnail: images.stone26,
  },
  {
    category: "stones",
    name: images.stone27.alt,
    thumbnail: images.stone27,
  },
  {
    category: "stones",
    name: images.stone28.alt,
    thumbnail: images.stone28,
  },
  {
    category: "stones",
    name: images.stone29.alt,
    thumbnail: images.stone29,
  },
  {
    category: "stones",
    name: images.stone30.alt,
    thumbnail: images.stone30,
  },
  {
    category: "stones",
    name: images.stone31.alt,
    thumbnail: images.stone31,
  },
  {
    category: "stones",
    name: images.stone32.alt,
    thumbnail: images.stone32,
  },
  {
    category: "stones",
    name: images.stone33.alt,
    thumbnail: images.stone33,
  },
  {
    category: "stones",
    name: images.stone34.alt,
    thumbnail: images.stone34,
  },
  {
    category: "stones",
    name: images.stone35.alt,
    thumbnail: images.stone35,
  },
  {
    category: "stones",
    name: images.stone36.alt,
    thumbnail: images.stone36,
  },
  {
    category: "stones",
    name: images.stone37.alt,
    thumbnail: images.stone37,
  },
  {
    category: "stones",
    name: images.stone38.alt,
    thumbnail: images.stone38,
  },
  {
    category: "stones",
    name: images.stone39.alt,
    thumbnail: images.stone39,
  },
  {
    category: "stones",
    name: images.stone40.alt,
    thumbnail: images.stone40,
  },
  {
    category: "stones",
    name: images.stone41.alt,
    thumbnail: images.stone41,
  },
  {
    category: "stones",
    name: images.stone42.alt,
    thumbnail: images.stone42,
  },
  {
    category: "stones",
    name: images.stone43.alt,
    thumbnail: images.stone43,
  },
  {
    category: "stones",
    name: images.stone44.alt,
    thumbnail: images.stone44,
  },
  {
    category: "stones",
    name: images.stone45.alt,
    thumbnail: images.stone45,
  },
  {
    category: "stones",
    name: images.stone46.alt,
    thumbnail: images.stone46,
  },
  {
    category: "stones",
    name: images.stone47.alt,
    thumbnail: images.stone47,
  },
  {
    category: "stones",
    name: images.stone48.alt,
    thumbnail: images.stone48,
  },
  {
    category: "stones",
    name: images.stone49.alt,
    thumbnail: images.stone49,
  },
  {
    category: "stones",
    name: images.stone50.alt,
    thumbnail: images.stone50,
  },
  {
    category: "stones",
    name: images.stone51.alt,
    thumbnail: images.stone51,
  },
  {
    category: "stones",
    name: images.stone52.alt,
    thumbnail: images.stone52,
  },
  {
    category: "stones",
    name: images.stone53.alt,
    thumbnail: images.stone53,
  },
  // {
  //   category: "stones",
  //   name: images.stone54.alt,
  //   thumbnail: images.stone54,
  // },
  
  // {
  //   category: "stones",
  //   name: images.stone4.alt,
  //   thumbnail: images.stone4,
  // },
  // {
  //   category: "stones",
  //   name: images.stone27.alt,
  //   thumbnail: images.stone27,
  // },
  // {
  //   category: "stones",
  //   name: images.stone28.alt,
  //   thumbnail: images.stone28,
  // },
  
  {
    category: "wood",
    name: images.wood1.alt,
    thumbnail: images.wood1,
  },
  {
    category: "wood",
    name: images.wood2.alt,
    thumbnail: images.wood2,
  },
  {
    category: "wood",
    name: images.wood3.alt,
    thumbnail: images.wood3,
  },
  {
    category: "wood",
    name: images.wood4.alt,
    thumbnail: images.wood4,
  },
];

export const findAll = (category_slug = null) => {
  if (category_slug) {
    // return _.find(datas, { 'category': category_slug });
    return _.filter(datas, { category: category_slug });
  } else {
    return datas;
  }
};

export const findBySlug = (slug) => {
  return _.find(datas, { slug: slug });
};
