import React, { useEffect, useRef, useState } from "react";
import SwiperCore, {
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

//images
// import productimg from "../../images/home/productimg.png";
import productimg from "../../images/home/productimg.png";
import productstone from "../../images/home/productstone.jpg";
import productwood from "../../images/home/woodfinish_1.jpg";
import { ctaRightArrow } from "../../images";
import { Link } from "react-router-dom";

const productsNamesArr = [
  "MCM Bricks Series",
  "MCM Stone Series",
  "MCM Wood Series",
];

const productImgArr = [productimg, productstone, productwood];
const productHollowNameArr = ["brick finish", "stone finish", "wood finish"];

const ProductSwiper = () => {
  const [productIndex, setProductIndex] = useState(0);
  const [sliderInstance, setSliderInstance] = useState();
  const sliderRef = useRef(null);

  const handleSlideIndex = (i) => {
    // product_img_wrapper.slideTo(i);
    // sliderInstance.slideTo(i);
    setProductIndex(i);
    sliderRef.current?.swiper.slideTo(i);
    console.log("button");
  };

  const productImgList = productImgArr.map((productImg, i) => (
    <SwiperSlide key={i}>
      <img
        src={productImg}
        alt="MCM product"
        loading="lazy"
        width="1672"
        height="864"
        className="product_img"
      />
    </SwiperSlide>
  ));

  const productsNamesList = productsNamesArr.map((productName, i) => (
    <span
      className={`${i === 1 ? "middle_product_name" : null} ${
        productIndex === i ? "product_name_active" : null
      }`}
      key={i}
      onClick={() => {
        handleSlideIndex(i);
      }}
    >
      {productName}
    </span>
  ));

  useEffect(() => {
    sliderRef.current?.swiper.on("slideChange", function (e) {
      // console.log("*** product_img_wrapper.activeIndex", sliderRef.activeIndex);
      setProductIndex(sliderRef.current?.swiper.activeIndex);
    });
  }, [sliderRef]);

  return (
    <>
      <div className="productsec_content">
        <span className="hollow_text">
          {productHollowNameArr[productIndex]}
        </span>
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
          ref={sliderRef}
          onSwiper={setSliderInstance}
        >
          {productImgList}
        </Swiper>
        <Link to="/products" className="mcm_cta">
          <span>know more</span>
          <img
            src={ctaRightArrow}
            alt="MCM button right arrow"
            loading="lazy"
          />
        </Link>
        <div className="text_container">
          <h3>products</h3>
          <p>
            MCM is an amalgamation of clay and sand through patented unfired
            manufacturing technology. Our wide range of products offer natural
            finishes with a perfect combination of style, aesthetics and
            performance.
          </p>
        </div>
        <div className="products_name_flex">
          {productsNamesList}
          <div className="blur_background"></div>
        </div>
      </div>
    </>
  );
};

export default ProductSwiper;
