import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Swiper, { Navigation, Autoplay } from "swiper";

//components
import Banner from "../../components/Banner/Banner";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./style.scss";
import {
  ctaRightArrow,
  images,
  navLeftArrow,
  navRightArrow,
} from "../../images";
import bannerimg from "../../images/about/bannerimg.jpg";

Swiper.use([Navigation, Autoplay]);

const Aboutus = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    var mile_swiper_container = new Swiper(
      ".swiper-container.mile_swiper_container",
      {
        loop: true,
        loopedSlides: 5,
        centeredSlides: true,
        navigation: {
          nextEl: ".mile_swiper_container .next_wrapper",
          prevEl: ".mile_swiper_container .prev_wrapper",
        },
        breakpoints: {
          0: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 5,
          },
        },
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
      }
    );
    var awards_swiper_container = new Swiper(
      ".swiper-container.awards_swiper_container",
      {
        freeMode: true,

        loop: true,
        loopedSlides: 7,
        centeredSlides: true,
        allowTouchMove: false,
        breakpoints: {
          0: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 5,
          },
        },
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
        },
        speed: 5000,
      }
    );
  }, []);
  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="about us"
        breadcrumb2="about us"
        disabledBreadcrumb2
      />

      <section className="aboutsec2">
        <div className="heading_container">
          <h2 className="page_heading">
            MCM- Pioneers and Inventors of Unfired Modified Clay Materials
          </h2>
        </div>
        <div className="about_flex">
          <div className="about_left">
            <div className="title_container">
              <h3 className="page_title">about MCM</h3>
            </div>
            <p className="sec2_desc">
              Exquisite exterior finishes and exclusive interiors are fabricated
              at MCMFLEXI Cladding India Pvt. Ltd. With its base in Mumbai, it
              is the most renowned, original and covetable pioneer player in the
              unfired clay cladding market in India. At MCM, we have set
              cross-national benchmarks with a concoction of technology,
              research and innovation.
              <br />
              <br /> MCMFLEXI offers the most varied collection of natural,
              durable, stylish, and aesthetic products under MCM Stone, MCM
              Bricks and MCM Wood categories for multipurpose exterior and
              interior requirements.
              <br />
              <br />
              MCM has a new-age commitment to promoting sustainable and
              revolutionary cladding to build smart and of its kind living
              spaces. The patented cladding automation is transforming the
              construction industry by providing fast-paced & lightweight
              cladding solutions for distinct types of structures.
              <br />
              <br />
              The clay is constituted from recycled materials to reduce the
              carbon footprint. Being the purest form of nature, our modified
              clay material has got a green rating across the globe.
            </p>
            {width > 767 ? (
              <Link to="/contact-us" className="mcm_cta">
                <span>get in touch</span>
                <img
                  src={ctaRightArrow}
                  alt="MCM button right arrow"
                  loading="lazy"
                />
              </Link>
            ) : null}
          </div>
          <div className="about_right">
            <img
              src={images.aboutusimg.image}
              alt={images.aboutusimg.alt}
              className="about_right_img"
              loading="lazy"
            />
            {width < 767 ? (
              <Link to="/contact-us" className="mcm_cta">
                <span>get in touch</span>
                <img
                  src={ctaRightArrow}
                  alt="MCM button right arrow"
                  loading="lazy"
                />
              </Link>
            ) : null}
          </div>
        </div>
      </section>

      <section className="aboutsec3">
        <span className="hollow_text">Our ethos</span>
        <div className="ethos_content_wrapper">
          <div className="ethos_text_wrapper">
            <p className="ethos_text">
              Innovative Ideas, Ethics and Principles drive MCM. We are an
              organization with values and culture where transparency,
              reliability and excellence play an essential part in our strategy.
              Our commitment is to provide practical cladding solutions that
              constantly drive us to evolve and enhance all our product
              offerings. We believe in growth by building long-term
              relationships with the client community, through technology and a
              responsible approach to the environment.
              <div className="blur_background"></div>
            </p>
          </div>
        </div>
      </section>

      {/* <section className="aboutsec4">
        <div className="text_container">
          <h3>milestones</h3>
          <p>
            The steadfast focus on quality, commitment and delivering excellence
            has shaped MCM to be a popular cladding title and one of the leading
            players in this industry. We share with you a glimpse of our journey
            over the years, marking several milestones along the way.
          </p>
        </div>
        <div className="mile_swiper_wrapper">
          <div className="swiper-container mile_swiper_container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <span className="mile_year">2001</span>
                <span className="mile_circle"></span>
                <p className="mile_desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore dolec
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo
                </p>
              </li>
              <li className="swiper-slide">
                <span className="mile_year">2002</span>
                <span className="mile_circle"></span>
                <p className="mile_desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore dolec
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo
                </p>
              </li>
              <li className="swiper-slide">
                <span className="mile_year">2003</span>
                <span className="mile_circle"></span>
                <p className="mile_desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore dolec
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo
                </p>
              </li>
              <li className="swiper-slide">
                <span className="mile_year">2004</span>
                <span className="mile_circle"></span>
                <p className="mile_desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore dolec
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo
                </p>
              </li>
              <li className="swiper-slide">
                <span className="mile_year">2005</span>
                <span className="mile_circle"></span>
                <p className="mile_desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore dolec
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo
                </p>
              </li>
            </ul>
            <img
              src={mileline}
              alt="MCM mile line"
              className="mile_line"
              loading="lazy"
            />
            <div className="prev_next_wrapper prev_wrapper">
              <img
                src={navLeftArrow}
                alt="MCM left arrow"
                className="nav_arrow"
                loading="lazy"
              />
              <p>prev</p>
            </div>
            <div className="prev_next_wrapper next_wrapper">
              <img
                src={navRightArrow}
                alt="MCM right arrow"
                className="nav_arrow"
                loading="lazy"
              />
              <p>next</p>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutsec5">
        <div className="team_flex">
          <div className="team_left">
            <div className="member_flex">
              <div className="member_left">
                <div className="member_wrapper">
                  <img
                    src="https://dummyimage.com/333x379/fdbgfd"
                    alt="MCM team member"
                    loading="lazy"
                    width="333"
                    height="379"
                    className="member_img"
                  />
                  <div className="member_info">
                    <span className="member_name">john doe</span>
                    <span className="member_profile">founder ceo</span>
                  </div>
                </div>
              </div>
              <div className="member_right">
                <div className="member_wrapper">
                  <img
                    src="https://dummyimage.com/333x302/fdbgfd"
                    alt="MCM team member"
                    loading="lazy"
                    width="333"
                    height="302"
                    className="member_img"
                  />
                  <div className="member_info">
                    <span className="member_name">john doe</span>
                    <span className="member_profile">founder ceo</span>
                  </div>
                </div>
                <div className="member_wrapper">
                  <img
                    src="https://dummyimage.com/333x302/fdbgfd"
                    alt="MCM team member"
                    loading="lazy"
                    width="333"
                    height="302"
                    className="member_img"
                  />
                  <div className="member_info">
                    <span className="member_name">john doe</span>
                    <span className="member_profile">founder ceo</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="team_right">
            <div className="text_container">
              <h3>our team</h3>
              <p>
                Every member of the MCM team has a role to play in leading the
                firm’s future growth. We are a close-knit talented group with a
                shared vision of consistently delivering great results for our
                clients and making the organization entertaining, engaging,
                inclusive, challenging workplace while building a rewarding
                career. Meet the MCM team below:
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutsec6">
        <div className="text_container">
          <h3>GLOBAL HONORS</h3>
          <p>
            At MCM, our unwavering endeavour of setting new standards and
            benchmarks time and again has won us numerous prestigious awards and
            accolades.
          </p>
        </div>
        <div className="awards_swiper_wrapper">
          <div className="swiper-container awards_swiper_container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
              <li className="swiper-slide">
                <img
                  src="https://dummyimage.com/333x379/fff"
                  alt="MCM awards"
                  className="awards_img"
                  loading="lazy"
                  width="261"
                  height="302"
                />
                <span className="awards_name">Lorem ipsum set</span>
                <span className="awards_year">2015</span>
              </li>
            </ul>
          </div>
          <img
            src={blurleft}
            alt="MCM blur"
            className="blur_left"
            loading="lazy"
          />
          <img
            src={blurright}
            alt="MCM blur"
            className="blur_right"
            loading="lazy"
          />
        </div>
      </section> */}
    </>
  );
};

export default Aboutus;
