import React, { useEffect, useState } from "react";
import "./style.scss";
//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";

//components
import Banner from "../../components/Banner/Banner";

//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { navLeftArrow, navRightArrow } from "../../images";

import {
  findBySlug as getSingleCategory,
  findAll as getAllCategories,
} from "../../Data/projectCategories";
import { findAll as getProjects } from "../../Data/projects";
import { Link } from "react-router-dom";

Swiper.use([Navigation, Autoplay]);

const ProjectsCategory = (props) => {
  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    var projects_swiper_container = new Swiper(
      ".swiper-container.projects_swiper_container",
      {
        // slidesPerView: 2.6,
        observer: true,
        allowTouchMove: true,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: ".projectcategorysec2 .nav_arrow_right",
          prevEl: ".projectcategorysec2 .nav_arrow_left",
        },
        breakpoints: {
          // 0: {
          //   slidesPerView: 2,
          //   spaceBetween: 20,
          // },
          767: {
            slidesPerView: 1.7,
          },
          1023: {
            slidesPerView: 2.1,
          },
          1194: {
            slidesPerView: 2.6,
          },
        },
      }
    );
    console.log("rerender");
  }, [projects, category]);

  useEffect(() => {
    const categoryData = getSingleCategory(props.match.params.slug);
    setCategory(categoryData);
    if (!categoryData) {
      props.history.push("/404");
    } else {
      const projectsData = getProjects(props.match.params.slug);
      const categoriesData = getAllCategories();

      setProjects(projectsData);
      setCategories(categoriesData);
    }
  }, [props.match.params.slug]);

  const projectList =
    projects.length > 1
      ? projects.map((item, i) => (
          <li className="swiper-slide" key={item.id}>
            <Link to={`/projects/${item.category}/${item.slug}`}>
              <figure>
                <img
                  src={item.thumbnail.image}
                  alt={item.name}
                  className="project_img"
                  loading="lazy"
                  width="590"
                  height="493"
                />
              </figure>
              <div className="project_name_wrapper">
                <span className="project_sequence">{++i}</span>
                <span className="project_name">{item.name}</span>
              </div>
            </Link>
          </li>
        ))
      : null;

  const CategoryList =
    categories.length > 1
      ? categories.map((item, i) => (
          <li
            className={`cat_name ${category.slug == item.slug ? "active" : ""}`}
            key={i}
          >
            <Link to={`/projects/${item.slug}`}>{item.title}</Link>
          </li>
        ))
      : null;

  return (
    <>
      <Banner
        imgSrc={category?.banner?.image}
        bannerTitle={category.title}
        breadcrumb2="projects"
        breadcrumbLink2="/projects"
        breadcrumb3={category.title}
        disabledBreadcrumb3
        dropShadow={true}
      />
      <section className="projectcategorysec2">
        <div className="title_flex">
          <div className="heading_container">
            <h3 className="category_title">{category.title}</h3>
          </div>
          {width > 767 ? (
            <div className="nav_arrow_wrapper">
              <img
                src={navLeftArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_left"
              />
              <img
                src={navRightArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_right"
              />
            </div>
          ) : null}
        </div>

        <div className="projects_slider">
          <div
            className="swiper-container projects_swiper_container"
            key={projects.length}
          >
            <ul className="swiper-wrapper">{projectList}</ul>
          </div>
          {width < 767 && projects.length > 2 ? (
            <div className="nav_arrow_wrapper">
              <img
                src={navLeftArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_left"
              />
              <img
                src={navRightArrow}
                alt="MCM navigation"
                loading="lazy"
                className="nav_arrow nav_arrow_right"
              />
            </div>
          ) : null}
        </div>

        <ul className="project_category_names_flex">
          {/* <li className="cat_name">external cladding</li>
          <li className="cat_name active">internal cladding</li> */}
          {CategoryList}
        </ul>
      </section>
    </>
  );
};

export default ProjectsCategory;
