import _ from "lodash";

import { images, projectCategoriesImg } from "../images";

export const datas = [
  {
    id: 1,
    title: "EXTERNAL CLADDING ",
    slug: "external-cladding",
    thumbnail: images.ExternalCladding,
    banner: projectCategoriesImg.external_cladding_banner,
  },
  {
    id: 2,
    title: "INTERNAL CLADDING",
    slug: "internal-cladding",
    thumbnail: images.InternalCladding,
    banner: projectCategoriesImg.internal_cladding_banner,
  },
];

export const findAll = () => {
  return datas;
};

export const findBySlug = (slug) => {
  return _.find(datas, { slug: slug });
};
