import React, { useState, useEffect } from "react";
import faqClose from "../../images/faq/faq-close.svg";
import faqOpen from "../../images/faq/faq-open.svg";
const FaqItem = ({
  faqTitle,
  faqDesc,
  faqIndex,
  handleFaqIndexClick,
  faqActiveIndex,
}) => {
  const [faqActive, setFaqActive] = useState(false);
  useEffect(() => {
    if (!(faqActiveIndex === faqIndex)) {
      setFaqActive(false); //for each faq it will check and make other off
    }
  }, [faqActiveIndex]);

  const handleFaqClick = () => {
    setFaqActive(!faqActive); //for single faq that you click will check make it on
  };

  return (
    <li
      className={`faq_item ${
        faqActiveIndex === faqIndex && faqActive ? "active" : null
      }`}
      onClick={() => {
        handleFaqIndexClick(faqIndex);
        handleFaqClick();
      }}
    >
      <h5 className="faq_title">{faqTitle}</h5>
      <p
        className={`faq_desc ${
          faqActiveIndex === faqIndex && faqActive ? "active" : null
        }`}
      >
        {faqDesc}
      </p>
      <img
        src={faqActiveIndex === faqIndex && faqActive ? faqClose : faqOpen}
        alt="FAQ close and open"
        className="faq_open_close_logo"
        loading="lazy"
      />
    </li>
  );
};

export default FaqItem;
