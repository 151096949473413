import React, { useEffect } from "react";
import "./style.scss";
//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";
//components
import Banner from "../../components/Banner/Banner";
import BlogCard from "../../components/BlogCard/BlogCard";

import bannerimg from "../../images/projects/bannerimg.jpg";
import { navLeftArrow, navRightArrow } from "../../images";
Swiper.use([Navigation, Autoplay]);

const BlogDetail = () => {
  useEffect(() => {
    var more_articles_swiper_container = new Swiper(
      ".swiper-container.more_articles_swiper_container",
      {
        navigation: {
          nextEl: ".more_articles_slider .nav_arrow_right",
          prevEl: ".more_articles_slider .nav_arrow_left",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1023: {
            slidesPerView: 3,
          },
        },
      }
    );
  }, []);
  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="blogs"
        breadcrumb2="blogs"
        disabledBreadcrumb2
        blogDetails
      />
      <section className="blogdetailsec2">
        <p className="blog_desc">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, rebum.
          Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
          dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
          justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
          diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
          et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. Lorem ipsum et ea rebum. Stet clita kasd
          gubergren Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet.
        </p>
      </section>
      <section className="blogdetailsec3">
        <h3>more articles</h3>
        <div className="more_articles_slider">
          <div className="swiper-container more_articles_swiper_container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <div className="blogdetails_wrapper_slide">
                  <BlogCard
                    blogImg="https://dummyimage.com/707x486/vbcbgc"
                    blogTitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
                    blogDesc="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum..."
                    blogDate="7th September, 2020"
                    blogCategory="Category"
                  />
                </div>
              </li>
              <li className="swiper-slide">
                <div className="blogdetails_wrapper_slide">
                  <BlogCard
                    blogImg="https://dummyimage.com/707x486/vbcbgc"
                    blogTitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
                    blogDesc="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum..."
                    blogDate="7th September, 2020"
                    blogCategory="Category"
                  />
                </div>
              </li>
              <li className="swiper-slide">
                <div className="blogdetails_wrapper_slide">
                  <BlogCard
                    blogImg="https://dummyimage.com/707x486/vbcbgc"
                    blogTitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
                    blogDesc="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum..."
                    blogDate="7th September, 2020"
                    blogCategory="Category"
                  />
                </div>
              </li>
              <li className="swiper-slide">
                <div className="blogdetails_wrapper_slide">
                  <BlogCard
                    blogImg="https://dummyimage.com/707x486/vbcbgc"
                    blogTitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
                    blogDesc="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo et ea rebum..."
                    blogDate="7th September, 2020"
                    blogCategory="Category"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="nav_arrow_wrapper">
            <img
              src={navLeftArrow}
              alt="MCM navigation"
              loading="lazy"
              className="nav_arrow nav_arrow_left"
            />
            <img
              src={navRightArrow}
              alt="MCM navigation"
              loading="lazy"
              className="nav_arrow nav_arrow_right"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
