import React, { useState } from "react";
import "./style.scss";

import { thankyouimg } from "../../images";
import Loader from "../../components/Loader/Loader";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Thankyou = () => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);
  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };
  return (
    <>
      <div
        style={{
          display: width > 992 && isLoader ? "block" : "none",
        }}
      >
        <Loader />
      </div>
      <section
        className="thankyousec"
        style={{ visibility: width > 992 && isLoader ? "hidden" : "visible" }}
      >
        <div className="text_wrapper">
          <h1>thank you!</h1>
          <p className="desc">
            for your enquiry, someone from our team will get back to you soon.
          </p>
          {/* <Link to="/contact-us" className="mcm_cta">
            <span>get in touch</span>
            <img src={ctaRightArrow} alt="MCM button right arrow" 
                loading="lazy" />
          </Link> */}
        </div>
        <div className="thankyouimg_wrapper">
          <img
            src={thankyouimg}
            alt="Thank you"
            loading="lazy"
            onLoad={thankyouimg ? imageLoaded : null}
          />
        </div>
      </section>
    </>
  );
};

export default Thankyou;
