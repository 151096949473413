export const eventsArr = [
  {
    img: "",
    title: "AD Show 2022",
    date: "December 2022",
    time: "",
    location: "Mumbai",
    href: "https://www.architecturaldigest.in/adds-2022/",
    article: "",
  },
  {
    img: "",
    title: "ICE EXPO 2022",
    date: "10th, 11th, 12th & 13th Nov",
    time: "",
    location: "Mumbai",
    href: "https://www.instagram.com/p/ClgTCE8K7Pr/?hl=en",
    article: "",
  },
  {
    img: "",
    title: "FOAID 2022",
    date: "7th , 8th & 9th October 2022",
    time: "",
    location: "Mumbai",
    href: "https://www.foaidindia.in/",
    article: "",
  },
  {
    img: "",
    title: "IIID Showcase InsiderX",
    date: "3rd, 4th & 5th June 2022",
    time: "",
    location: "Hyderabad",
    href: "https://businessnewsweek.in/business/the-3-days-iiid-design-showcase-insider-x-2022-to-showcase-awe-inspiring-innovative-design-options-from-june-3rd/",
    article:
      "IIID – Hyderabad Regional Chapter, is hosting the Fourth Edition of its prestigious flagship event “IIID Showcase Insider X 2022”, with the theme Telangana Art and Craft, from the 3rd to 5th June 2022 at Hitex Exhibition Centre.",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Guwahati Edition",
    date: "25th February 2022",
    time: "",
    location: "Guwahati",
    href: "https://www.instagram.com/p/CahbQUJo6c5/?hl=en",
    article:
      "Perspective Guwahati Edition created an enigmatic experience for the audience and the brands through engaging discussions and product display.",
  },
  {
    img: "",
    title: "FOAID 2021 - Mumbai Edition",
    date: "29th November 2021 - 30th November 2021",
    time: "",
    location: "Mumbai",
    href: "https://www.futurarc.com/happening/foaid/",
    article:
      "The Mumbai edition of the Festival of Architecture and Interior Designing (FOAID) was held on 8th and 9th December, 2018.",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Kolkata Edition",
    date: "12th November 2021",
    time: "",
    location: "Kolkata",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Jaipur Edition",
    date: "29th October 2021",
    time: "",
    location: "Jaipur",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Chennai Edition",
    date: "09th October 2021",
    time: "",
    location: "Chennai",
    href: "https://www.facebook.com/designperspectiveindia/posts/we-are-excited-to-have-siraj-hasan-of-siraj-and-renu-bengaluru-hyderabad-chennai/2849526658654573/",
    article:
      "We are excited to have Siraj Hasan of Siraj and Renu (Bengaluru, Hyderabad, Chennai) as the Guest Speaker at Design Perspective, Raipur Edition on 12th March 2021.",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Chandigarh Edition",
    date: "24th September 2021",
    time: "",
    location: "Chandigarh",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Ahmedabad Edition",
    date: "03rd September 2021",
    time: "",
    location: "Ahmedabad",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Bengaluru Edition",
    date: "20th August 2021",
    time: "",
    location: "Bengaluru",
    href: "https://www.facebook.com/designperspectiveindia/posts/we-are-excited-to-have-siraj-hasan-of-siraj-and-renu-bengaluru-hyderabad-chennai/2849526658654573/",
    article:
      "We are excited to have Siraj Hasan of Siraj and Renu (Bengaluru, Hyderabad, Chennai) as the Guest Speaker at Design Perspective, Raipur Edition on 12th March 2021.",
  },
  {
    img: "",
    title: "Design Perspective 2021 - Hyderabad Edition",
    date: "26th March 2021",
    time: "",
    location: "Hyderabad",
    href: "https://www.facebook.com/designperspectiveindia/posts/we-are-excited-to-have-siraj-hasan-of-siraj-and-renu-bengaluru-hyderabad-chennai/2849526658654573/",
    article:
      "We are excited to have Siraj Hasan of Siraj and Renu (Bengaluru, Hyderabad, Chennai) as the Guest Speaker at Design Perspective, Raipur Edition on 12th March 2021.",
  },
  {
    img: "",
    title: "Architectural Digest Design Show",
    date: "18th October 2019 - 20th October 2019",
    time: "",
    location: "Mumbai",
    href: "https://www.architecturaldigest.in/content/mcm-unfired-clay-cladding/",
    article:
      "As its name suggests, MCM Unfired Clay Cladding is well known for its high-quality, sustainable façade solutions and decorative cladding for landscaping and internal spaces.",
  },
  {
    img: "",
    title: "Dialogues 2019 - Jodhpur Edition",
    date: "03rd October 2019 - 05th October 2019",
    time: "",
    location: "Jodhpur",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "FOAID 2019 - New Delhi Edition",
    date: "20th September 2019 - 21st September 2019",
    time: "",
    location: "New Delhi",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Jaipur Edition",
    date: "09th August 2019",
    time: "",
    location: "Jaipur",
    href: "https://www.facebook.com/designperspectiveindia/photos/a.1646909552249629/2372455169695060/?type=3",
    article:
      "Murad Ismail from MIDC Associates, Sri Lanka, shared some insightful perspectives on architecture at our Jaipur edition of Design Perspective 2019.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Chandigarh Edition",
    date: "19th July 2019",
    time: "",
    location: "Chandigarh",
    href: "https://www.facebook.com/designperspectiveindia/posts/top-eminent-architects-from-chandigarh-explore-the-topic-psychology-and-design-t/2358748961065681/",
    article:
      "Top eminent architects from Chandigarh explore the topic ‘Psychology and Design: The Impact of Architecture and Design in Shaping behavior and Psychology’ in an engaging panel discussion.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Hyderabad Edition",
    date: "05th July 2019",
    time: "",
    location: "Hyderabad",
    href: "https://www.facebook.com/SampadaSohoni.Hyderabad/",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Kolkata Edition",
    date: "22nd June 2019",
    time: "",
    location: "Kolkata",
    href: "https://www.facebook.com/designperspectiveindia/photos/a.1527642507509668/2339266833013894/?type=3",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Guhawati Edition",
    date: "07th June 2019",
    time: "",
    location: "Guhawati",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Ahmedabad Edition",
    date: "24th May 2019",
    time: "",
    location: "Ahmedabad",
    href: "https://m.facebook.com/designperspectiveindia/posts/2320671504873427",
    article: "",
  },
  {
    img: "",
    title: "INDEX Fairs 2019",
    date: "01st May 2019 - 04th May 2019",
    time: "",
    location: "Mumbai ",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Bengaluru  Edition",
    date: "26th April 2019",
    time: "",
    location: "Bengaluru ",
    href: "https://medium.com/@shirinkhara/takeaways-from-designup-2019-441664d2bc0f",
    article:
      "Earlier this month, I conducted a workshop at the DesignUp 2019 conference held in Bangalore.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Pune Edition",
    date: "12th April 2019",
    time: "",
    location: "Pune",
    href: "https://www.facebook.com/designperspectiveindia/posts/sanjay-kothari-of-kaleido-shares-his-viewpoint-at-our-jaipur-edition-of-design-p/2373072746299969/",
    article:
      "Sanjay Kothari of KALEIDO shares his viewpoint at our Jaipur edition of Design Perspective 2019.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Chennai Edition",
    date: "22nd March 2019",
    time: "",
    location: "Chennai",
    href: "https://www.facebook.com/watch/?v=512864922941853",
    article:
      "Design Perspective proudly presents 2020 Chennai Edition at The Leela Palace on the 28 February, 2020.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Bhubaneswar Edition",
    date: "09th March 2019",
    time: "",
    location: "Bhubaneswar",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "iDAC Expo 2019",
    date: "08th March 2019 - 10th March 2019",
    time: "",
    location: "Mumbai",
    href: "https://www.instagram.com/p/Bk7ZhnQhxQ-/?tagged=idac2019&hl=en",
    article:
      "IDAC Expo 2019 provides a unique platform that joins forces from the Infrastructure, Architecture, Development, and Construction industry to create the change. The exhibition to be held on 15th, 16th and 17th of February, 2019 at Bombay Exhibition Centre, Mumbai.",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Nagpur Edition",
    date: "23rd February 2019",
    time: "",
    location: "Nagpur",
    href: "https://www.nationnext.com/design-perspective-foaid-nagpur-2019/",
    article:
      "Creative minds gathered together to share innovative design ideas at Design Perspective? organised by FOAID (Festival of Architecture and Interior Designing) in association with Nation Next as the media partner on February 23 at Hotel Radisson Blu in Nagpur. Design Perspective, which brought architects and interior designers under one roof, was supported by Nagpur Regional Chapter of IIID (Institute of Indian Interior Designers).",
  },
  {
    img: "",
    title: "Design Perspective 2019 - Kochi Edition",
    date: "8th February 2019",
    time: "",
    location: "Kochi",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Jaipur Architecture Festival",
    date: "1st February 2019 - 2nd February 2019",
    time: "",
    location: "Jaipur",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "Interiors",
    date: "January 2019",
    time: "",
    location: "Kolkata",
    href: "",
    article: "",
  },
  {
    img: "",
    title: "IIA Assam Chapter",
    date: "October 2018",
    time: "",
    location: "Guwahati",
    href: "https://www.facebook.com/The-Indian-Institute-of-Architects-Assam-Chapter-1538355633104175/",
    article: "",
  },
  {
    img: "",
    title: "Architectural Digest Design Show",
    date: "October 2018",
    time: "",
    location: "Mumbai",
    href: "https://www.architecturaldigest.in/content/ad-design-show-2018-heres-recap-best-category-showcase-art-design/",
    article:
      "The inaugural AD Design Show 2018 made a superlative debut on October 26, 27 and 28 under the stunning Dome@NSCI, SVP Stadium, in Worli.",
  },
  {
    img: "",
    title: "Dialogues 2018 - Hyderabad Edition",
    date: "September 2018",
    time: "",
    location: "Hyderabad",
    href: "https://www.thehansindia.com/posts/index/Young-Hans/2018-05-07/ISB-hosts-the-first-edition-of-Deccan-Dialogue-in-Hyderabad/379377",
    article:
      " Indian School of Business (ISB), in partnership with Ministry of External Affairs, organised the maiden edition of Deccan Dialogue focusing on the theme ‘Economic Diplomacy for Development’.",
  },
  {
    img: "",
    title: "IIID Showcase",
    date: "February 2018",
    time: "",
    location: "Indore",
    href: "https://www.freepressjournal.in/cmcm/indore-build-dream-homes-well-within-your-budget-with-iiid-showcase",
    article:
      "Indore: ‘Showcase -2018’, an exhibition of designer home products by Institute of Indian Interior Designer (IIID), began on a promising note here on Thursday, with a cache of modern furniture and other products put on display for public by more than 151 participating companies from across the globe. Moreover, many companies even launched their products at the event.",
  },
  {
    img: "",
    title: "Constro",
    date: "18th January 2018 - 21st January 2018",
    time: "",
    location: "Pune",
    href: "https://engmag.in/build-construct-your-business-to-unprecedented-altitudes-with-a-platform-that-reins-supreme-constro-international-fair-2018/",
    article:
      "ABEC Exhibitions & Conferences Pvt. Ltd. & ITE proudly partnered with Pune Construction Engineering Research Foundation PCERF with a vision of taking the building and construction industry to greater heights, and organized Constro International Fair 2018 Pune, through the 18th to the 21st of January 2018 at the Agriculture College Ground in Pune.",
  },
  {
    img: "",
    title: "Constro",
    date: "14th January 2016 - 17th January 2016",
    time: "",
    location: "Pune",
    href: "https://www.thehindubusinessline.com/news/constro-2016-to-focus-on-smart-city-concept/article64311241.ece",
    article:
      "The 2016 edition of the Constro exhibition will be held in Pune from January 14-17 and will focus on the Smart City concept.",
  },
  {
    img: "",
    title: "Arch Dakshin",
    date: "August 2016",
    time: "",
    location: "Hyderabad",
    href: "https://www.business-standard.com/article/pti-stories/arch-dakshin-2016-in-hyderabad-from-aug-19-116081600735_1.html",
    article:
      "The Telangana Chapter of Indian Institute of Architects (IIA) will host the second Southern Regional Conference- 'Arch Dakshin 2016' for two days from August 19, here.",
  },
  {
    img: "",
    title: "Acetech Mumbai",
    date: "28th October 2015 - 1st November 2015",
    time: "",
    location: "Mumbai",
    href: "https://etacetech.com/acetech_interface.html",
    article:
      "Encouraged by the optimistic response to ACETECH each year, ACETECH 2015 embarked on the road to pursue its aim and vision, to widen the reach of the platform, in order to ensure it becomes the converging point for Industry specialists across the globe.",
  },
];
