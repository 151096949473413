import React from "react";
import "./fonts.css";
import "./common.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import "swiper/swiper-bundle.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";

const App = () => {
  return (
    <>
      <MetaDecorator
        metaTitle="MCM Cladding"
        metaDesc="MCM Cladding"
        canonicalLink={window.location.href}
      />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
