import _ from "lodash";
import { images } from "../images";
export const datas = [
  {
    id: 1,
    category: "internal-cladding",
    name: "35 Piece Stack Stone",
    slug: "35-piece-stack-stone",
    thumbnail: images.piecestackstonesite1thumb,
    banner: images.piecestackstonesite1one,
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore dolec magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore dolec magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore dolec magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore dolec magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
    galleries: [
      {
        type: "image",
        thumbnail: images.piecestackstonesite1one,
      },
    ],
  },
  {
    id: 2,
    category: "internal-cladding",
    name: "Bricks A308317- Private Residence",
    slug: "bricks-a308317-private-residence",
    thumbnail: images.brickssitethumb,
    banner: images.brickssiteone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.brickssiteone,
      },
    ],
  },
  {
    id: 3,
    category: "internal-cladding",
    name: "Bricks K052- Centrum IT Park - Mumbai",
    slug: "bricks-k052-centrum-park-mumbai",
    thumbnail: images.bricksk052thumb,
    banner: images.bricksk052one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksk052one,
      },
      {
        type: "image",
        thumbnail: images.bricksk052two,
      },
      {
        type: "image",
        thumbnail: images.bricksk052three,
      },
    ],
  },
  {
    id: 4,
    category: "internal-cladding",
    name: "Bricks K326C4",
    slug: "bricks-k326C4",
    thumbnail: images.bricksk326c4thumb,
    banner: images.bricksk326c4one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksk326c4one,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4two,
      },
    ],
  },
  {
    id: 5,
    category: "internal-cladding",
    name: "Bricks K228030 - Transcon Developers Mumbai",
    slug: "bricks-k228030-transcon-developers-mumbai",
    thumbnail: images.bricksk228030thumb,
    banner: images.bricksk228030one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksk228030one,
      },
      {
        type: "image",
        thumbnail: images.bricksk228030two,
      },
      {
        type: "image",
        thumbnail: images.bricksk228030three,
      },
      {
        type: "image",
        thumbnail: images.bricksk228030four,
      },
    ],
  },
  {
    id: 6,
    category: "internal-cladding",
    name: "Bricks R 087089 - Commercial Space - Indonesia",
    slug: "bricks-r-087089-commercial-space-indonesia",
    thumbnail: images.bricksr087089thumb,
    banner: images.bricksr087089one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksr087089one,
      },
      {
        type: "image",
        thumbnail: images.bricksr087089two,
      },
      {
        type: "image",
        thumbnail: images.bricksr087089three,
      },
      {
        type: "image",
        thumbnail: images.bricksr087089four,
      },
      {
        type: "image",
        thumbnail: images.bricksr087089five,
      },
      {
        type: "image",
        thumbnail: images.bricksr087089six,
      },
    ],
  },
  {
    id: 7,
    category: "internal-cladding",
    name: "Bricks-A221228231-Abhmanshree Society",
    slug: "bricks-a221228231-abhmanshree-society",
    thumbnail: images.bricksa221228231thumb,
    banner: images.bricksa221228231one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksa221228231one,
      },
      {
        type: "image",
        thumbnail: images.bricksa221228231two,
      },
    ],
  },
  // {
  //   id: 8,
  //   category: "internal-cladding",
  //   name: "Concerto 052 - Jolly Banerjee Residence",
  //   slug: "concerto-052-jolly-banerjee-residence",
  //   thumbnail: images.concertostonethumb,
  //   banner: images.concertostoneone,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.concertostoneone,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.concertostonetwo,
  //     },
  //   ],
  // },
  {
    id: 9,
    category: "internal-cladding",
    name: "Concreto- Internal Cladding- Wet Area",
    slug: "concreto-internal-cladding-wet-area",
    thumbnail: images.concertowetthumb,
    banner: images.concertowetone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.concertowetone,
      },
      {
        type: "image",
        thumbnail: images.concertowettwo,
      },
      {
        type: "image",
        thumbnail: images.concertowetthree,
      },
      {
        type: "image",
        thumbnail: images.concertowetfour,
      },
    ],
  },
  // {
  //   id: 10,
  //   category: "internal-cladding",
  //   name: "Croco",
  //   slug: "croco",
  //   thumbnail: images.crocothumb,
  //   banner: images.crocoone,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.crocoone,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.crocotwo,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.crocothree,
  //     },
  //   ],
  // },
  // {
  //   id: 11,
  //   category: "internal-cladding",
  //   name: "Croco - Office Internal Wall Cladding",
  //   slug: "croco-office-internal-wall-cladding",
  //   thumbnail: images.crocoleatherthumb,
  //   banner: images.crocoleatherone,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.crocoleatherone,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.crocoleathertwo,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.crocoleatherthree,
  //     },
  //   ],
  // },
  {
    id: 12,
    category: "internal-cladding",
    name: "Crossard Mushroom",
    slug: "crossard-mushroom",
    thumbnail: images.crossardmushroomthumb,
    banner: images.crossardmushroomone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.crossardmushroomone,
      },
      {
        type: "image",
        thumbnail: images.crossardmushroomtwo,
      },
      {
        type: "image",
        thumbnail: images.crossardmushroomthree,
      },
      {
        type: "image",
        thumbnail: images.crossardmushroomfour,
      },
      {
        type: "image",
        thumbnail: images.crossardmushroomfive,
      },
    ],
  },
  {
    id: 13,
    category: "internal-cladding",
    name: "Cutstone - Commercial Space",
    slug: "cutstone-commercial-space",
    thumbnail: images.cutstonespacethumb,
    banner: images.cutstonespaceone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.cutstonespaceone,
      },
      {
        type: "image",
        thumbnail: images.cutstonespacetwo,
      },
      {
        type: "image",
        thumbnail: images.cutstonespacethree,
      },
      {
        type: "image",
        thumbnail: images.cutstonespacefour,
      },
    ],
  },
  {
    id: 14,
    category: "internal-cladding",
    name: "Devine Mushroom",
    slug: "devine-mushroom",
    thumbnail: images.devinemushroom052thumb,
    banner: images.devinemushroom052one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.devinemushroom052one,
      },
      {
        type: "image",
        thumbnail: images.devinemushroom052two,
      },
      {
        type: "image",
        thumbnail: images.devinemushroom052three,
      },
    ],
  },
  {
    id: 15,
    category: "internal-cladding",
    name: "Foodmall - Lonavala",
    slug: "foodmall-lonavala",
    thumbnail: images.foodmallthumb,
    banner: images.foodmallone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.foodmallone,
      },
      {
        type: "image",
        thumbnail: images.foodmalltwo,
      },
      {
        type: "image",
        thumbnail: images.foodmallthree,
      },
      {
        type: "image",
        thumbnail: images.foodmallfour,
      },
      {
        type: "image",
        thumbnail: images.foodmallfive,
      },
    ],
  },
  {
    id: 16,
    category: "internal-cladding",
    name: "Golden Lolite",
    slug: "golden-lolite",
    thumbnail: images.goldenlolitethumb,
    banner: images.goldenloliteone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.goldenloliteone,
      },
      {
        type: "image",
        thumbnail: images.goldenlolitetwo,
      },
      {
        type: "image",
        thumbnail: images.goldenlolitethree,
      },
      {
        type: "image",
        thumbnail: images.goldenlolitefour,
      },
    ],
  },
  {
    id: 17,
    category: "internal-cladding",
    name: "Granite - Restaurant",
    slug: "granite-restaurant",
    thumbnail: images.granite043thumb,
    banner: images.granite043one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.granite043one,
      },
      {
        type: "image",
        thumbnail: images.granite043two,
      },
      {
        type: "image",
        thumbnail: images.granite043three,
      },
    ],
  },
  {
    id: 18,
    category: "internal-cladding",
    name: "Granite 052 - Private Residence",
    slug: "granite-052-private-residence",
    thumbnail: images.granite052thumb,
    banner: images.granite052one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.granite052one,
      },
      {
        type: "image",
        thumbnail: images.granite052two,
      },
    ],
  },
  {
    id: 19,
    category: "internal-cladding",
    name: "Granite 052- Indonesia Cafe",
    slug: "granite-052-indonesia-cafe",
    thumbnail: images.granite052indonesiacafethumb,
    banner: images.granite052indonesiacafeone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.granite052indonesiacafeone,
      },
      {
        type: "image",
        thumbnail: images.granite052indonesiacafetwo,
      },
    ],
  },
  {
    id: 20,
    category: "internal-cladding",
    name: "Infiniti Stone - Bombay Cocktail Bar Mumbai",
    slug: "infiniti-stone-bombay-cocktail-bar-mumbai",
    thumbnail: images.infinitistonethumb,
    banner: images.infinitistoneone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.infinitistoneone,
      },
      {
        type: "image",
        thumbnail: images.infinitistonetwo,
      },
      {
        type: "image",
        thumbnail: images.infinitistonethree,
      },
      {
        type: "image",
        thumbnail: images.infinitistonefour,
      },
      {
        type: "image",
        thumbnail: images.infinitistonefive,
      },
    ],
  },
  {
    id: 21,
    category: "internal-cladding",
    name: "Ink Painting",
    slug: "ink-painting",
    thumbnail: images.inkpaintingthumb,
    banner: images.inkpaintingone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.inkpaintingone,
      },
      {
        type: "image",
        thumbnail: images.inkpaintingtwo,
      },
      {
        type: "image",
        thumbnail: images.inkpaintingthree,
      },
    ],
  },
  {
    id: 22,
    category: "internal-cladding",
    name: "Jagged Wood Olympus - Ceiling Cladding - Bengaluru",
    slug: "jagged-wood-olympus-ceiling-cladding-bengaluru",
    thumbnail: images.jaggedwoodthumb,
    banner: images.jaggedwoodone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.jaggedwoodone,
      },
      {
        type: "image",
        thumbnail: images.jaggedwoodtwo,
      },
      {
        type: "image",
        thumbnail: images.jaggedwoodthree,
      },
      {
        type: "image",
        thumbnail: images.jaggedwoodfour,
      },
      {
        type: "image",
        thumbnail: images.jaggedwoodfive,
      },
    ],
  },
  {
    id: 23,
    category: "internal-cladding",
    name: "JW Mariott Nashik, Bricks A087089",
    slug: "jw-mariott-nashik-bricks-a087089",
    thumbnail: images.jwmariottthumb,
    banner: images.jwmariottone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.jwmariottone,
      },
      {
        type: "image",
        thumbnail: images.jwmariotttwo,
      },
    ],
  },
  {
    id: 24,
    category: "internal-cladding",
    name: "Oasis Stone - Private Residence - Pune",
    slug: "oasis-stone-private-residence-pune",
    thumbnail: images.oasisstone043thumb,
    banner: images.oasisstone043one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.oasisstone043one,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043two,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043three,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043four,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043five,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043six,
      },
      {
        type: "image",
        thumbnail: images.oasisstone043seven,
      },
    ],
  },
  // {
  //   id: 25,
  //   category: "internal-cladding",
  //   name: "Rockface Stone 043 - Private Villa",
  //   slug: "rockface-stone-043-private-villa",
  //   thumbnail: images.rockface043thumb,
  //   banner: images.rockface043one,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.rockface043one,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.rockface043two,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.rockface043three,
  //     },
  //   ],
  // },
  // {
  //   id: 26,
  //   category: "internal-cladding",
  //   name: "Rockface- Lounge",
  //   slug: "rockface-lounge",
  //   thumbnail: images.rockfacestonethumb,
  //   banner: images.rockfacestoneone,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.rockfacestoneone,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.rockfacestonetwo,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.rockfacestonethree,
  //     },
  //   ],
  // },
  // {
  //   id: 27,
  //   category: "internal-cladding",
  //   name: "Rockface- Residential",
  //   slug: "rockface-residential",
  //   thumbnail: images.rockface052thumb,
  //   banner: images.rockface052one,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.rockface052one,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.rockface052two,
  //     },
  //   ],
  // },
  {
    id: 28,
    category: "internal-cladding",
    name: "Sandstone + Oasis Stone - Chennai Metro Ltd",
    slug: "sandstone-oasis-stone-chennai-metro-ltd",
    thumbnail: images.sandstoneoasisstonethumb,
    banner: images.sandstoneoasisstoneone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.sandstoneoasisstoneone,
      },
      {
        type: "image",
        thumbnail: images.sandstoneoasisstonetwo,
      },
      {
        type: "image",
        thumbnail: images.sandstoneoasisstonethree,
      },
      {
        type: "image",
        thumbnail: images.sandstoneoasisstonefour,
      },
    ],
  },
  {
    id: 29,
    category: "internal-cladding",
    name: "The Garden Club Bricks A308317",
    slug: "the-garden-club-bricks-a308317",
    thumbnail: images.bricksa308317thumb,
    banner: images.bricksa308317one,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksa308317one,
      },
      {
        type: "image",
        thumbnail: images.bricksa308317two,
      },
    ],
  },
  {
    id: 30,
    category: "internal-cladding",
    name: "Travertine on Pillar - Phoenix Marketcity Mall, Kurla",
    slug: "travertine-pillar-phoenix-marketcity-mall-kurla",
    thumbnail: images.travertinecurvedthumb,
    banner: images.travertinecurvedone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.travertinecurvedone,
      },
      {
        type: "image",
        thumbnail: images.travertinecurvedtwo,
      },
      {
        type: "image",
        thumbnail: images.travertinecurvedthree,
      },
    ],
  },
  {
    id: 31,
    category: "internal-cladding",
    name: "Wood - Farm House in Chikmagalur",
    slug: "wood-farm-house-chikmagalur",
    thumbnail: images.woodfarmthumb,
    banner: images.woodfarmone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.woodfarmone,
      },
      {
        type: "image",
        thumbnail: images.woodfarmtwo,
      },
      {
        type: "image",
        thumbnail: images.woodfarmthree,
      },
      {
        type: "image",
        thumbnail: images.woodfarmfour,
      },
      {
        type: "image",
        thumbnail: images.woodfarmfive,
      },
      {
        type: "image",
        thumbnail: images.woodfarmsix,
      },
    ],
  },
  {
    id: 32,
    category: "internal-cladding",
    name: "Wood - Flooring",
    slug: "wood-flooring",
    thumbnail: images.woodflooringthumb,
    banner: images.woodflooringone,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.woodflooringone,
      },
      {
        type: "image",
        thumbnail: images.woodflooringtwo,
      },
      {
        type: "image",
        thumbnail: images.woodflooringthree,
      },
      {
        type: "image",
        thumbnail: images.woodflooringfour,
      },
      {
        type: "image",
        thumbnail: images.woodflooringfive,
      },
    ],
  },
  {
    id: 33,
    category: "external-cladding",
    name: "Private Garden - Indonesia",
    slug: "private-garden-indonesia",
    thumbnail: images.privategardenindonesiathumb,
    banner: images.privategardenindonesia1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.privategardenindonesia1,
      },
      {
        type: "image",
        thumbnail: images.privategardenindonesia2,
      },
      {
        type: "image",
        thumbnail: images.privategardenindonesia3,
      },
      // {
      //   type: "video",
      //   thumbnail: projectsImg.hale_warehouse_mint_plaza_gallery_1,
      //   embed_code: "3elGSZSWTbM",
      // },
    ],
  },
  {
    id: 34,
    category: "external-cladding",
    name: "USHAKAL Sangli Hospital - Maharashtra",
    slug: "uash-hospital-maharashtra",
    thumbnail: images.uashhospitalthumb,
    banner: images.uashhospital1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.uashhospital1,
      },
      {
        type: "image",
        thumbnail: images.uashhospital2,
      },
      {
        type: "image",
        thumbnail: images.uashhospital3,
      },
      {
        type: "image",
        thumbnail: images.uashhospital4,
      },
      {
        type: "image",
        thumbnail: images.uashhospital5,
      },
      {
        type: "image",
        thumbnail: images.uashhospital6,
      },
      {
        type: "image",
        thumbnail: images.uashhospital7,
      },
    ],
  },
  {
    id: 35,
    category: "external-cladding",
    name: "Foodmall - Mumbai-Pune Expressway",
    slug: "foodmall-mumbai-pune-expressway",
    thumbnail: images.foodmallexpresswaythumb,
    banner: images.foodmallexpressway1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.foodmallexpressway1,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway2,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway3,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway4,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway5,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway6,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway7,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway8,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway9,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway10,
      },
      {
        type: "image",
        thumbnail: images.foodmallexpressway11,
      },
    ],
  },
  {
    id: 36,
    category: "external-cladding",
    name: "Shri Omkar Developer Mumbai",
    slug: "shri-omkar-developer-mumbai",
    thumbnail: images.shriomkarthumb,
    banner: images.shriomkar1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.shriomkar1,
      },
      {
        type: "image",
        thumbnail: images.shriomkar2,
      },
      {
        type: "image",
        thumbnail: images.shriomkar3,
      },
      {
        type: "image",
        thumbnail: images.shriomkar4,
      },
      {
        type: "image",
        thumbnail: images.shriomkar5,
      },
      {
        type: "image",
        thumbnail: images.shriomkar6,
      },
    ],
  },
  {
    id: 37,
    category: "external-cladding",
    name: "Sumit Woods - Mumbai",
    slug: "sumit-woods-mumbai",
    thumbnail: images.sumitwoodsthumb,
    banner: images.sumitwoods1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.sumitwoods1,
      },
      {
        type: "image",
        thumbnail: images.sumitwoods2,
      },
      {
        type: "image",
        thumbnail: images.sumitwoods3,
      },
      {
        type: "image",
        thumbnail: images.sumitwoods4,
      },
    ],
  },
  {
    id: 38,
    category: "external-cladding",
    name: "Private Villa Crossard Mushroom Stone",
    slug: "private-villa-crossard-mushroom-stone",
    thumbnail: images.privatevillacrossardmushroomthumb,
    banner: images.privatevillacrossardmushroom1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.privatevillacrossardmushroom1,
      },
      {
        type: "image",
        thumbnail: images.privatevillacrossardmushroom2,
      },
    ],
  },
  {
    id: 39,
    category: "external-cladding",
    name: "The Hanger Fashion Outlet - Indonesia",
    slug: "the-hanger-fashion-outlet-indonesia",
    thumbnail: images.hangerfashionoutletthumb,
    banner: images.hangerfashionoutlet1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.hangerfashionoutlet1,
      },
      {
        type: "image",
        thumbnail: images.hangerfashionoutlet2,
      },
      {
        type: "image",
        thumbnail: images.hangerfashionoutlet3,
      },
      {
        type: "image",
        thumbnail: images.hangerfashionoutlet4,
      },
      {
        type: "image",
        thumbnail: images.hangerfashionoutlet5,
      },
    ],
  },
  {
    id: 40,
    category: "external-cladding",
    name: "Bricks G Series - Residential Building",
    slug: "bricks-g-series-residential-building",
    thumbnail: images.bricksgseriesresidentialthumb,
    banner: images.bricksgseriesresidential1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksgseriesresidential1,
      },
      {
        type: "image",
        thumbnail: images.bricksgseriesresidential2,
      },
    ],
  },
  {
    id: 41,
    category: "external-cladding",
    name: "Balaji Medical Centre - Chennai",
    slug: "balaji-medical-centre-chennai",
    thumbnail: images.balajimedicalcentrethumb,
    banner: images.balajimedicalcentre1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.balajimedicalcentre1,
      },
      {
        type: "image",
        thumbnail: images.balajimedicalcentre2,
      },
      {
        type: "image",
        thumbnail: images.balajimedicalcentre3,
      },
      {
        type: "image",
        thumbnail: images.balajimedicalcentre4,
      },
    ],
  },
  {
    id: 42,
    category: "external-cladding",
    name: "BRTS Pune",
    slug: "brts-pune",
    thumbnail: images.brtspunethumb,
    banner: images.brtspune1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.brtspune1,
      },
      {
        type: "image",
        thumbnail: images.brtspune2,
      },
      {
        type: "image",
        thumbnail: images.brtspune3,
      },
      {
        type: "image",
        thumbnail: images.brtspune4,
      },
    ],
  },
  {
    id: 43,
    category: "external-cladding",
    name: "Hyatt Centric, Goa",
    slug: "hyatt-centric-goa",
    thumbnail: images.hyattcentricgoathumb,
    banner: images.hyattcentricgoa1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.hyattcentricgoa1,
      },
      {
        type: "image",
        thumbnail: images.hyattcentricgoa2,
      },
      {
        type: "image",
        thumbnail: images.hyattcentricgoa3,
      },
    ],
  },
  {
    id: 44,
    category: "external-cladding",
    name: "Bricks K326C4 - Residential Building, Mumbai",
    slug: "bricks-k326c4-residential-building-mumbai",
    thumbnail: images.bricksk326c4mthumb,
    banner: images.bricksk326c4m1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksk326c4m1,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m2,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m3,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m4,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m5,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m5,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m6,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4m7,
      },
    ],
  },
  // {
  //   id: 45,
  //   category: "external-cladding",
  //   name: "Private Villa - Rockface Stone Cladding",
  //   slug: "private-villa-rockface-stone-cladding",
  //   thumbnail: images.privatevillarockfacestonecladdingthumb,
  //   banner: images.privatevillarockfacestonecladding1,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.privatevillarockfacestonecladding1,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.privatevillarockfacestonecladding2,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.privatevillarockfacestonecladding3,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.privatevillarockfacestonecladding4,
  //     },
  //   ],
  // },
  {
    id: 46,
    category: "external-cladding",
    name: "Oasis Stone Compound Wall Cladding - Mumbai",
    slug: "oasis-stone-compound-wall-cladding-mumbai",
    thumbnail: images.oasisstonewallthumb,
    banner: images.oasisstonewall1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.oasisstonewall1,
      },
      {
        type: "image",
        thumbnail: images.oasisstonewall2,
      },
      {
        type: "image",
        thumbnail: images.oasisstonewall3,
      },
      {
        type: "image",
        thumbnail: images.oasisstonewall4,
      },
    ],
  },
  {
    id: 47,
    category: "external-cladding",
    name: "Bricks K326C4 - Private Residence - Bengaluru",
    slug: "bricks-k326c4-private-residence-bengaluru",
    thumbnail: images.bricksk326c4bengaluruthumb,
    banner: images.bricksk326c4bengaluru1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.bricksk326c4bengaluru1,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4bengaluru2,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4bengaluru3,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4bengaluru4,
      },
      {
        type: "image",
        thumbnail: images.bricksk326c4bengaluru5,
      },
    ],
  },
  {
    id: 48,
    category: "external-cladding",
    name: "Oasis Stone - Commercial Complex - Hyderabad",
    slug: "oasis-stone-commercial-complex-hyderabad",
    thumbnail: images.oasisstonehyderabadthumb,
    banner: images.oasisstonehyderabad1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.oasisstonehyderabad1,
      },
      {
        type: "image",
        thumbnail: images.oasisstonehyderabad2,
      },
      {
        type: "image",
        thumbnail: images.oasisstonehyderabad3,
      },
      {
        type: "image",
        thumbnail: images.oasisstonehyderabad4,
      },
    ],
  },
  {
    id: 49,
    category: "external-cladding",
    name: "Click Hotel - Bengaluru",
    slug: "click-hotel-bengaluru",
    thumbnail: images.clickhotelthumb,
    banner: images.clickhotel1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.clickhotel1,
      },
      {
        type: "image",
        thumbnail: images.clickhotel2,
      },
      {
        type: "image",
        thumbnail: images.clickhotel3,
      },
      {
        type: "image",
        thumbnail: images.clickhotel4,
      },
    ],
  },
  {
    id: 50,
    category: "external-cladding",
    name: "My Home Bhooja - Hyderabad",
    slug: "home-bhooja-hyderabad",
    thumbnail: images.homebhoojathumb,
    banner: images.homebhooja1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.homebhooja1,
      },
      {
        type: "image",
        thumbnail: images.homebhooja2,
      },
      {
        type: "image",
        thumbnail: images.homebhooja3,
      },
      {
        type: "image",
        thumbnail: images.homebhooja4,
      },
      {
        type: "image",
        thumbnail: images.homebhooja5,
      },
      {
        type: "image",
        thumbnail: images.homebhooja6,
      },
    ],
  },
  {
    id: 51,
    category: "external-cladding",
    name: "Happy Home - Mumbai",
    slug: "happy-home-mumbai",
    thumbnail: images.happyhomethumb,
    banner: images.happyhome1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.happyhome1,
      },
      {
        type: "image",
        thumbnail: images.happyhome2,
      },
      {
        type: "image",
        thumbnail: images.happyhome3,
      },
      {
        type: "image",
        thumbnail: images.happyhome4,
      },
      {
        type: "image",
        thumbnail: images.happyhome5,
      },
      {
        type: "image",
        thumbnail: images.happyhome6,
      },
      {
        type: "image",
        thumbnail: images.happyhome7,
      },
    ],
  },
  {
    id: 52,
    category: "external-cladding",
    name: "Commercial Site - Oasis Stone",
    slug: "commercial-site-oasis-stone",
    thumbnail: images.commercialoasisthumb,
    banner: images.commercialoasis1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.commercialoasis1,
      },
      {
        type: "image",
        thumbnail: images.commercialoasis2,
      },
    ],
  },
  {
    id: 53,
    category: "external-cladding",
    name: "Private Villa - Oasis Stone",
    slug: "private-villa-oasis-stone",
    thumbnail: images.privatevillaoasisstonethumb,
    banner: images.privatevillaoasisstone1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.privatevillaoasisstone1,
      },
      {
        type: "image",
        thumbnail: images.privatevillaoasisstone2,
      },
      {
        type: "image",
        thumbnail: images.privatevillaoasisstone3,
      },
    ],
  },
  {
    id: 54,
    category: "external-cladding",
    name: "Slate Sunnye + Wood - Private Villa, Hyderabad",
    slug: "slate-sunnye-wood-private-villa-hyderabad",
    thumbnail: images.slatesunnyewoodthumb,
    banner: images.slatesunnyewood1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.slatesunnyewood1,
      },
      {
        type: "image",
        thumbnail: images.slatesunnyewood2,
      },
      {
        type: "image",
        thumbnail: images.slatesunnyewood3,
      },
    ],
  },
  {
    id: 55,
    category: "external-cladding",
    name: "35 Piece Stack Stone - Residential Site - North East",
    slug: "35-piece-stack-stone-residential-site-north-east",
    thumbnail: images.piecestackstonethumb,
    banner: images.piecestackstone1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.piecestackstone1,
      },
      {
        type: "image",
        thumbnail: images.piecestackstone2,
      },
      {
        type: "image",
        thumbnail: images.piecestackstone3,
      },
      {
        type: "image",
        thumbnail: images.piecestackstone4,
      },
    ],
  },
  {
    id: 56,
    category: "external-cladding",
    name: "The Ornate Hotel - Travertine",
    slug: "the-ornate-hotel-travertine",
    thumbnail: images.ornatehotelthumb,
    banner: images.ornatehotel1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.ornatehotel1,
      },
      {
        type: "image",
        thumbnail: images.ornatehotel2,
      },
      {
        type: "image",
        thumbnail: images.ornatehotel3,
      },
    ],
  },
  {
    id: 57,
    category: "external-cladding",
    name: "Infiniti Stone 043 - Private Residence",
    slug: "infiniti-stone-043-private-residence",
    thumbnail: images.infinitistone043thumb,
    banner: images.infinitistone0431,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.infinitistone0431,
      },
      {
        type: "image",
        thumbnail: images.infinitistone0432,
      },
      {
        type: "image",
        thumbnail: images.infinitistone0433,
      },
    ],
  },
  {
    id: 58,
    category: "external-cladding",
    name: "G Series Facing Bricks - Residential Site",
    slug: "g-series-facing-bricks-residential-site",
    thumbnail: images.gseriesfacingthumb,
    banner: images.gseriesfacing1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.gseriesfacing1,
      },
      {
        type: "image",
        thumbnail: images.gseriesfacing2,
      },
    ],
  },
  // {
  //   id: 59,
  //   category: "external-cladding",
  //   name: "Oasis Stone + Concerto - Private Residence Jaipur",
  //   slug: "oasis-stone-concerto-private-residence-jaipur",
  //   thumbnail: images.concertojaipurthumb,
  //   banner: images.concertojaipur1,
  //   title: "",
  //   description: "",
  //   galleries: [
  //     {
  //       type: "image",
  //       thumbnail: images.concertojaipur1,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.concertojaipur2,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.concertojaipur3,
  //     },
  //     {
  //       type: "image",
  //       thumbnail: images.concertojaipur4,
  //     },
  //   ],
  // },
  {
    id: 60,
    category: "external-cladding",
    name: "Bricks K326C4 - Appaswamy Bloomingdale",
    slug: "bricks-k326c4-appaswamy-bloomingdale",
    thumbnail: images.appaswamybloomingdalethumb,
    banner: images.appaswamybloomingdale1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.appaswamybloomingdale1,
      },
      {
        type: "image",
        thumbnail: images.appaswamybloomingdale2,
      },
      {
        type: "image",
        thumbnail: images.appaswamybloomingdale3,
      },
      {
        type: "image",
        thumbnail: images.appaswamybloomingdale4,
      },
      {
        type: "image",
        thumbnail: images.appaswamybloomingdale5,
      },
    ],
  },
  {
    id: 61,
    category: "external-cladding",
    name: "Oasis Foge - Private Residence",
    slug: "oasis-foge-private-residence",
    thumbnail: images.oasisfogethumb,
    banner: images.oasisfoge1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.oasisfoge1,
      },
      {
        type: "image",
        thumbnail: images.oasisfoge2,
      },
      {
        type: "image",
        thumbnail: images.oasisfoge3,
      },
      {
        type: "image",
        thumbnail: images.oasisfoge4,
      },
      {
        type: "image",
        thumbnail: images.oasisfoge5,
      },
    ],
  },
  {
    id: 62,
    category: "external-cladding",
    name: "Slate Arte - AERA Site",
    slug: "slate-arte-aera-site",
    thumbnail: images.slateartethumb,
    banner: images.slatearte1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.slatearte1,
      },
      {
        type: "image",
        thumbnail: images.slatearte2,
      },
      {
        type: "image",
        thumbnail: images.slatearte3,
      },
      {
        type: "image",
        thumbnail: images.slatearte4,
      },
      {
        type: "image",
        thumbnail: images.slatearte5,
      },
      {
        type: "image",
        thumbnail: images.slatearte6,
      },
    ],
  },
  {
    id: 63,
    category: "external-cladding",
    name: "Sandstone - Commercial Site",
    slug: "sandstone-commercial-site",
    thumbnail: images.sandstonethumb,
    banner: images.sandstone1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.sandstone1,
      },
      {
        type: "image",
        thumbnail: images.sandstone2,
      },
      {
        type: "image",
        thumbnail: images.sandstone3,
      },
      {
        type: "image",
        thumbnail: images.sandstone4,
      },
    ],
  },
  {
    id: 64,
    category: "external-cladding",
    name: "Travertine 052KZ - External Cladding",
    slug: "travertine-052kz-external-cladding",
    thumbnail: images.travertine052kzthumb,
    banner: images.travertine052kz1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.travertine052kz1,
      },
      {
        type: "image",
        thumbnail: images.travertine052kz2,
      },
      {
        type: "image",
        thumbnail: images.travertine052kz3,
      },
    ],
  },
  {
    id: 65,
    category: "external-cladding",
    name: "Slate Arte - Private Villa - Hyderabad",
    slug: "slate-arte-private-villa-hyderabad",
    thumbnail: images.slatearteprivatehyderabadthumb,
    banner: images.slatearteprivatehyderabad1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.slatearteprivatehyderabad1,
      },
      {
        type: "image",
        thumbnail: images.slatearteprivatehyderabad2,
      },
      {
        type: "image",
        thumbnail: images.slatearteprivatehyderabad3,
      },
      {
        type: "image",
        thumbnail: images.slatearteprivatehyderabad4,
      },
    ],
  },
  {
    id: 66,
    category: "external-cladding",
    name: "Travertine 052KZ, Bricks - Atharva Site",
    slug: "travertine-052kz-bricks-atharva-site",
    thumbnail: images.travertine052kzatharvathumb,
    banner: images.travertine052kzatharva1,
    title: "",
    description: "",
    galleries: [
      {
        type: "image",
        thumbnail: images.travertine052kzatharva1,
      },
      {
        type: "image",
        thumbnail: images.travertine052kzatharva2,
      },
      {
        type: "image",
        thumbnail: images.travertine052kzatharva3,
      },
      {
        type: "image",
        thumbnail: images.travertine052kzatharva4,
      },
      {
        type: "image",
        thumbnail: images.travertine052kzatharva5,
      },
      {
        type: "image",
        thumbnail: images.travertine052kzatharva6,
      },
    ],
  },
];

export const findAll = (category_slug = null) => {
  if (category_slug) {
    // return _.find(datas, { 'category': category_slug });
    return _.filter(datas, { category: category_slug });
  } else {
    return datas;
  }
};

export const findBySlug = (slug) => {
  return _.find(datas, { slug: slug });
};
