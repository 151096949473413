import React from "react";
import "./style.scss";

//components
import Banner from "../../components/Banner/Banner";

//images
import bannerimg from "../../images/projects/project_banner.jpg";
import { Link } from "react-router-dom";
import { findAll as getAllCategories } from "../../Data/projectCategories";

const categories = getAllCategories();

const OurProjects = () => {
  const categoryList = categories.map((item, i) => (
    <div className="project_category" key={i}>
      <Link to={`/projects/${item.slug}`}>
        <img
          src={item.thumbnail.image}
          alt={item.thumbnail.alt}
          loading="lazy"
          width="760"
          height="542"
          className="projects_category_img"
        />
        <span className="hollow_text">{item.title}</span>
      </Link>
    </div>
  ));

  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="projects"
        breadcrumb2="projects"
        disabledBreadcrumb2
      />
      <section className="projectssec2">
        <p className="desc">
          Specializing in custom colour and exquisite finishes, we’ve created
          elegant and award-winning spaces for world-wide projects. From
          effectively managing expansive projects in the most remote places with
          ease to catering to different sectors like Commercial, Residential,
          Institutional, Hospitality and more, our external and internal
          cladding portfolio is truly a reflection of that.
        </p>
        <div className="projects_category_flex">{categoryList}</div>
      </section>
    </>
  );
};

export default OurProjects;
