import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Loader from "../Loader/Loader";
const Banner = ({
  imgSrc,
  bannerTitle,
  breadcrumb2,
  breadcrumb3,
  breadcrumb4,
  breadcrumbLink2,
  breadcrumbLink3,
  disabledBreadcrumb2,
  disabledBreadcrumb3,
  blogDetails,
  dropShadow,
}) => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: width > 992 && isLoader ? "block" : "none",
        }}
      >
        <Loader />
      </div>
      <section
        className={`banner_wrapper ${dropShadow ? "dropshadow" : ""}`}
        style={{ visibility: width > 992 && isLoader ? "hidden" : "visible" }}
      >
        {width > 767 ? (
          <div className="container">
            <div className="reveal">
              <img
                src={imgSrc}
                alt="MCM about us"
                className="bannerimg"
                onLoad={imgSrc ? imageLoaded : null}
              />
            </div>
          </div>
        ) : null}
        <h1 className="banner_title">{bannerTitle}</h1>
        {blogDetails && (
          <div className="common_blog_details_wrapper">
            <span>Lorem Author's Name | </span>
            <span>7th September, 2021 |</span>
            <span> Category</span>
          </div>
        )}
        <div className="breadcrumb_wrapper">
          <Link to="/" className="breadcrumb_link">
            home
          </Link>{" "}
          <Link
            to={breadcrumbLink2 ? breadcrumbLink2 : "/"}
            className={`breadcrumb_link ${
              disabledBreadcrumb2 ? "disabled" : null
            }`}
          >
            / {breadcrumb2}
          </Link>
          {breadcrumb3 ? (
            <Link
              to={breadcrumbLink3 ? breadcrumbLink3 : "/"}
              className={`breadcrumb_link ${
                disabledBreadcrumb3 ? "disabled" : null
              }`}
            >
              &nbsp;/ {breadcrumb3}
            </Link>
          ) : null}
          {breadcrumb4 ? (
            <span className="breadcrumb_link"> / {breadcrumb4}</span>
          ) : null}
        </div>
      </section>
    </>
  );
};
export default Banner;
