import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import Banner from "../../components/Banner/Banner";

import useWindowDimensions from "../../hooks/useWindowDimensions";

//images
// import bannerimg from "../../images/products/bannerimg.jpg";
import bannerimg from "../../images/products/Product_banner_1.jpg";

import { downArrow } from "../../images";
import { findAll as getAllCategories } from "../../Data/productCategories";
import { findAll as getProducts } from "../../Data/products";

const OurProducts = (props) => {
  const [categories, setCategories] = useState([]);
  const [stones, setStones] = useState([]);
  const { width } = useWindowDimensions();
  const [activeCategory, setActiveCategory] = useState();

  const product2ref = useRef();

  // const getScrollTriggerConfig = (triggerEl) => {
  //   return {
  //     y: 0,
  //     // opacity: 1,
  //     scrollTrigger: {
  //       trigger: product2ref.current.querySelector(triggerEl),
  //       scrub: true,
  //       start: window.innerWidth > 768 ? `top 80%` : `top 100%`,
  //       end: window.innerWidth > 768 ? `top 60%` : `top 800%`,
  //       markers: false,
  //       ease: Expo.easeInOut,
  //     },
  //   };
  // };

  // const fadeAnimsec2 = (triggerEl) => {
  //   return {
  //     opacity: 1,
  //     scrollTrigger: {
  //       trigger: product2ref.current.querySelector(triggerEl),
  //       scrub: true,
  //       start: window.innerWidth > 768 ? `top 90%` : `top 100%`,
  //       end: window.innerWidth > 768 ? `top 60%` : `top 80%`,
  //       markers: false,
  //       ease: Expo.easeInOut,
  //     },
  //   };
  // };

  const handleCategoryChange = (slug) => {
    setActiveCategory(slug);

    const stonesData = getProducts(slug);
    setStones(stonesData);
  };

  const handleMobileCategoryChange = (e) => {
    const slug = e.target.value;
    setActiveCategory(slug);

    const stonesData = getProducts(slug);
    setStones(stonesData);
  };

  const categoryList = categories.map((item, i) => (
    <div className="category_name_wrapper" key={i}>
      <div
        className={`category_name ${
          item.slug === activeCategory ? "active" : ""
        }`}
        key={item.id}
        onClick={() => handleCategoryChange(item.slug)}
      >
        {item.title}
      </div>
    </div>
  ));

  const mbCategoryList = categories.map((item, i) => (
    <option value={item.slug} key={i}>
      {item.title}
    </option>
  ));

  const categoryDataList = categories.map((item, i) => (
    <div
      className={`category_info_flex ${
        item.slug === activeCategory ? "active" : ""
      }`}
    >
      <div className="category_info_left">
        <div className="text_container">
          <h3>MCM {item.title}</h3>
          <p>{item.desc}</p>
        </div>
      </div>
      <div className="category_info_right">
        <img
          src={item.image}
          alt="MCM product"
          className="product_img"
          width="871"
          height="621"
          loading="lazy"
        />
        <span className="hollow_text">{item.title} Finish</span>
      </div>
    </div>
  ));

  // ============================== BY KHALID ANSARI ==================================
  const productSubCategoryList = categories.map((item, i) => (
    <option
      key={i}
      value={item.slug}
      // onClick={(e) => handleSubCatClick(e, i)}
      className="color_list"
    >
      {item.slug}
    </option>
  ));

  const stonesList = stones?.map((item, i) => (
    <div className="product_card" key={i}>
      <img
        src={item.thumbnail.image}
        alt={item.name}
        className="product_img"
        loading="lazy"
        width="385"
        height="493"
      />
      <span>{item.name}</span>
    </div>
  ));

  useEffect(() => {
    const categoriesData = getAllCategories();

    const defaultSlug = props.location.state?.slug;
    if (defaultSlug) {
      setActiveCategory(defaultSlug);
    } else {
      setActiveCategory(categoriesData[0]?.slug);
    }

    if (categoriesData) {
      const stonesData = getProducts(defaultSlug);
      // const stonesData = getProducts(categoriesData[0].slug);
      setCategories(categoriesData);
      setStones(stonesData);
    }

    // console.log(categoriesData[defaultIndex].slug);
  }, [props.location.state]);

  return (
    <>
      <Banner
        imgSrc={bannerimg}
        bannerTitle="products"
        breadcrumb2="products"
        disabledBreadcrumb2
      />
      <section className="productssec2" ref={product2ref}>
        <p className="products_desc">
          MCM products are a unique combination of their appearance, usability
          and sustainability. From traditional to contemporary textures and
          elegant designs, our products are available in diverse finishes and
          colour options to perfectly suit the exterior and interior surfaces.
        </p>

        <>
          {width > 767 ? (
            <div className="product_category_flex">{categoryList}</div>
          ) : (
            <>
              <div className="choose_color_wrapper mb_choose_color_wrapper">
                <select
                  onChange={handleMobileCategoryChange}
                  className="category_dropdown"
                  value={activeCategory}
                >
                  {mbCategoryList}
                </select>
                <img
                  src={downArrow}
                  alt="down arrow"
                  loading="lazy"
                  className="down_arrow_img"
                />
              </div>
            </>
          )}
        </>

        <div>{categoryDataList}</div>

        <div className="product_select_flex">
          <div className="choose_color_wrapper mb_product_select_choose_color_wrapper">
            <select
              className="choose_color"
              value={activeCategory}
              onChange={handleMobileCategoryChange}
            >
              {productSubCategoryList}
            </select>
            <img
              src={downArrow}
              alt="down arrow"
              loading="lazy"
              className="down_arrow_img"
            />
          </div>
        </div>

        <div className="product_card_flex">{stonesList}</div>
        {/* <div className="load_more_wrapper">
          <span className="load_more_cta">load more</span>
        </div> */}
      </section>
    </>
  );
};

export default OurProducts;
