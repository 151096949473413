import React from "react";
import "./style.scss";
//components
import Banner from "../../components/Banner/Banner";
//images
import downloadimg1 from "../../images/downloads/downloadimg1.jpg";
import downloadimg2 from "../../images/downloads/downloadimg2.jpg";
import downloadIcon from "../../images/downloads/download-icon.svg";
import { brochurePDF, images, ittimipdf } from "../../images";

const downloadsData = [
  {
    link: brochurePDF,
    thumbnail: downloadimg1,
    title: "MCM Brochure",
  },
  {
    link: ittimipdf,
    thumbnail: downloadimg2,
    title: "ittimi crafted interior cladding",
  },
];

const Downloads = () => {
  const downloadsList = downloadsData.map((item, i) => (
    <li className="download_box" key={i}>
      <a href={item.link} download>
        <img
          src={item.thumbnail}
          alt="download img"
          loading="lazy"
          width="500"
          height="500"
          className="download_img"
        />
        <h5>{item.title}</h5>
        <img
          src={downloadIcon}
          alt="Download icon"
          className="download_icon"
          loading="lazy"
        />
      </a>
    </li>
  ));

  return (
    <>
      <Banner
        imgSrc={images.downloadbanner.image}
        bannerTitle="Downloads"
        breadcrumb2="Downloads"
        disabledBreadcrumb2
      />
      <section className="downloadssec2">
        <ul className="downloads_flex">{downloadsList}</ul>
        {/* <div className="load_more_wrapper">
          <span className="load_more_cta">load more</span>
        </div> */}
      </section>
    </>
  );
};

export default Downloads;
