import React from "react";
import "./style.scss";
import { ctaRightArrow } from "../../images";
import { Link } from "react-router-dom";

const BlogCard = ({ blogImg, blogTitle, blogDesc, blogDate, blogCategory }) => {
  return (
    <div className="blog_card">
      <img
        src={blogImg}
        alt="MCM featured blog"
        loading="lazy"
        width="506"
        height="380"
        className="blog_img"
      />
      <div className="blog_details_wrapper">
        <div className="blog_date_and_cat">
          <span>{blogDate} | </span> <span>{blogCategory}</span>
        </div>
        <h4 className="blog_title">{blogTitle}</h4>
        <p className="blog_desc">{blogDesc}</p>
        <Link to="/products" className="mcm_cta">
          <span>read more</span>
          <img
            src={ctaRightArrow}
            alt="MCM button right arrow"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
